import React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box } from "@mui/material";
import { CobaltIcon } from "da-design-system";

const CAIHPrediceError = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      pt={2}
      pr={2}
      pb={2}
      pl={8}
      sx={{
        backgroundColor: "cobalt.bleuDigital05",
        borderRadius: "4px",
        color: "cobalt.ultramarine",
        position: "relative",
        fontSize: isMobile ? "12px" : "14px",
        lineHeight: isMobile ? "12px" : "20px"
      }}
    >
      Problème lors de la récupération du statut depuis la plateforme Prédice.
      <CobaltIcon
        name="info"
        style={{
          color: "cobalt.ultramarine",
          fontSize: "18px",
          position: "absolute",
          top: isMobile ? 20 : 26,
          left: 26
        }}
      />
    </Box>
  );
};

export default CAIHPrediceError;
