import React from "react";
import { Grid, Typography } from "@mui/material";
import { CobaltIcon } from "da-design-system";

const IppTooltip = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h4" sx={{ color: "cobalt.white" }}>
          Où puis-je trouver mon IPP ?
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" sx={{ color: "cobalt.white" }}>
          <CobaltIcon
            name="circle-fill"
            style={{
              fontSize: "12px",
              position: "absolute",
              top: 5,
              left: -20
            }}
          />
          Sur les documents fournis par votre établissement de santé.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" sx={{ color: "cobalt.white" }}>
          <CobaltIcon
            name="circle-fill"
            style={{
              fontSize: "12px",
              position: "absolute",
              top: 5,
              left: -20
            }}
          />
          Auprès de votre établissement de santé.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IppTooltip;
