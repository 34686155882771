import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMediaQuery, Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ConsentSuccessIllustration from "../../images/success-consents-illus.png";
import LogoLayout from "../../components/LogoLayout";

function ConsentSuccess(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { state } = location;
  let clinicLogo = null;
  let clinicGHTLogo = null;
  let clinicLegalNotice = "";
  let clinicGtu = "";

  if (state === undefined) {
    history.push("/");
  } else {
    clinicLogo = state.clinicLogo;
    clinicGHTLogo = state.clinicGHTLogo;
    clinicLegalNotice = state.clinicLegalNotice;
    clinicGtu = state.clinicGtu;
  }

  return (
    <LogoLayout
      containerSize="lg"
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
    >
      <Box m={isMobile ? 2 : 5} pb={isMobile ? 12 : 0}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sm={7}>
            <Box mb={4}>
              <Typography variant="h1">{t("consentSuccess:title")}</Typography>
            </Box>
            <Typography variant="subtitle1">
              {t("consentSuccess:subtitle")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              src={ConsentSuccessIllustration}
              alt="Success consentement"
              style={{ width: "90%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>
      {clinicGHTLogo && (
        <Box>
          <Box
            pt={isMobile ? 2 : 5}
            pr={isMobile ? 2 : 5}
            pb={isMobile ? 2 : 5}
            pl={5}
            sx={{
              backgroundColor: "white",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              position: "absolute",
              right: 0,
              bottom: isMobile ? 0 : 62,
              left: 0
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={`data:image/png;base64,${clinicGHTLogo}`}
                alt="Logo GHT"
                style={{
                  width: "90%",
                  maxWidth: isMobile ? "90px" : "140px",
                  height: "auto"
                }}
              />
            </Grid>
          </Box>
        </Box>
      )}
    </LogoLayout>
  );
}

ConsentSuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      clinicLogo: PropTypes.string.isRequired,
      clinicGHTLogo: PropTypes.string.isRequired,
      clinicLegalNotice: PropTypes.string.isRequired,
      clinicGtu: PropTypes.string.isRequired
    })
  }).isRequired
};

export default ConsentSuccess;
