import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { Typography, Box, Grid } from "@mui/material";
import { CobaltIcon } from "da-design-system";

const NewConsentErrorBlock = () => {
  const { t } = useTranslation();

  return (
    <Box color="error.main" mb={4} mt={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3} md={2} sx={{ textAlign: "center" }}>
          <CobaltIcon name="alert-circle" style={{ fontSize: "24px" }} />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <Box>
            <Typography gutterBottom>{t("newConsent:error.label1")}</Typography>
            <Typography>{t("newConsent:error.label2")}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(NewConsentErrorBlock);
