import { isValid, isAfter } from "date-fns";

const isMobileValid = phone => {
  return phone.match("^(\\+33|0033|0)(6|7)[0-9]{8}$");
};

const isDateValid = date => {
  return isValid(date) && isAfter(date, new Date(1900, 0, 0));
};

export default { isMobileValid, isDateValid };
