import { format } from "date-fns";
import * as yup from "yup";

const civility = ["Tuteur", "Parent 1", "Parent 2"];
const legalReasons = [
  "Décédé(e)",
  "Inconnu/un seul représentant légal",
  "Non joignable",
  "Autre",
];

const getISODate = (date) => format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

const getStandardDate = (date) => format(date, "yyyy-MM-dd");

const PatientValidationSchema = yup.object().shape({
  legalRepresentative1: yup.object().shape({
    lastName: yup
      .string()
      .required("Le nom de famille est requis")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Nom invalide"),
    firstName: yup
      .string()
      .required("Le prénom doit être renseigné")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Nom invalide"),
    email: yup
      .string()
      .required("L'adresse email est requise")
      .email("Adresse email invalide")
      .test(
        "email-match",
        "Les adresses email des représentants légaux ne doivent pas être identiques",
        // eslint-disable-next-line func-names
        function(value, context) {
          const lg2Email = context.from[1].value.legalRepresentative2.email;
          return value !== lg2Email;
        }
      ),
    mobileNumber: yup
      .string("Le numéro saisi n'est pas valide")
      .required("Un numéro de téléphone est requis")
      .matches(/^[0-9]/, "Numéro de téléphone invalide")
      .test(
        "mobileNumber-match",
        "Les coordonnées des représentants légaux ne doivent pas être identiques",
        // eslint-disable-next-line func-names
        function(value, context) {
          const lg2MobileNumber =
            context.from[1].value.legalRepresentative2.mobileNumber;
          return value !== lg2MobileNumber;
        }
      ),
  }),
  legalRepresentative2: yup.object().shape({
    lastName: yup
      .string()
      .required("Le nom de famille est requis")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Nom invalide"),
    firstName: yup
      .string()
      .required("Le prénom doit être renseigné")
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Nom invalide"),
    email: yup
      .string()
      .required("L'adresse email est requise")
      .email("Adresse email invalide")
      .test(
        "email-match",
        "Les adresses email des représentants légaux ne doivent pas être identiques",
        // eslint-disable-next-line func-names
        function(value, context) {
          const lg1Email = context.from[1].value.legalRepresentative1.email;
          return value !== lg1Email;
        }
      ),
    mobileNumber: yup
      .string("Le numéro saisi n'est pas valide")
      .required("Un numéro de téléphone est requis")
      .matches(/^[0-9]/, "Numéro de téléphone invalide")
      .test(
        "mobileNumber-match",
        "Les coordonées des représentants légaux ne doivent pas être identiques",
        // eslint-disable-next-line func-names
        function(value, context) {
          const lg1MobileNumber =
            context.from[1].value.legalRepresentative1.mobileNumber;
          return value !== lg1MobileNumber;
        }
      ),
  }),
  complementaryReason: yup
    .string()
    .max(100, "Votre message ne doit pas excéder les 100 caractères"),
});

const fields = [];

fields.RP1 = [
  {
    label: "Civilité",
    choices: ["Mme", "M"],
    placeholder: "-",
    name: "legalRepresentative1.civility",
    item: "select",
    xs: 2,
  },
  {
    label: "Nom",
    name: "legalRepresentative1.lastName",
    placeholder: "Nom du représentant légal",
    defaultValue: "",
    item: "field",
    xs: 5,
  },
  {
    label: "Prénom",
    name: "legalRepresentative1.firstName",
    placeholder: "Prénom du représentant légal",
    defaultValue: "",
    item: "field",
    xs: 5,
  },
  {
    label: "Qualité",
    choices: civility,
    name: "legalRepresentative1.quality",
    item: "select",
    placeholder: "",
    xs: 3,
  },
  {
    label: "Email",
    name: "legalRepresentative1.email",
    type: "email",
    placeholder: "adresse@email.com",
    defaultValue: "",
    rules: {
      maxLength: 2,
    },
    item: "field",
    xs: 4.5,
  },
  {
    label: "Téléphone",
    name: "legalRepresentative1.mobileNumber",
    placeholder: "Numéro de téléphone",
    type: "tel",
    defaultValue: "",
    item: "field",
    xs: 4.5,
  },
];

fields.RP2 = [
  {
    label: "Civilité",
    choices: ["Mme", "M"],
    placeholder: "-",
    name: "legalRepresentative2.civility",
    item: "select",
    xs: 2,
  },
  {
    label: "Nom",
    name: "legalRepresentative2.lastName",
    placeholder: "Nom du représentant légal 2",
    defaultValue: "",
    item: "field",
    xs: 5,
  },
  {
    label: "Prénom",
    name: "legalRepresentative2.firstName",
    placeholder: "Prénom du représentant légal 2",
    defaultValue: "",
    item: "field",
    xs: 5,
  },
  {
    label: "Qualité",
    choices: civility,
    name: "legalRepresentative2.quality",
    item: "select",
    placeholder: "",
    xs: 3,
  },
  {
    label: "Email",
    name: "legalRepresentative2.email",
    placeholder: "adresse@email.com",
    type: "email",
    defaultValue: "",
    rules: {
      maxLength: 2,
    },
    item: "field",
    xs: 4.5,
  },
  {
    label: "Téléphone",
    name: "legalRepresentative2.mobileNumber",
    placeholder: "Numéro de téléphone",
    type: "tel",
    defaultValue: "",
    item: "field",
    xs: 4.5,
  },
];

fields.singleRP = [
  {
    label: "Raison",
    choices: legalReasons,
    name: "reason",
    item: "select",
    placeholder: "Sélectionnez la raison",
    xs: 12,
  },
  {
    label: "Précisez",
    name: "complementaryReason",
    type: "text",
    item: "field",
    placeholder: "Précisez",
    xs: 12,
  },
];

export default {
  civility,
  getISODate,
  getStandardDate,
  PatientValidationSchema,
  fields,
};
