import React from "react";
import { SvgIcon } from "@mui/material";

const PreferenceIcon = props => {
  return (
    <SvgIcon
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.05 60.5631C27.05 60.5631 50.7309 48.7226 50.7309 30.962V11.2165L27.05 1.36084L3.36914 11.2165V21.0892V30.962C3.36914 48.7226 27.05 60.5631 27.05 60.5631Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9389 22.7625C20.7682 22.7625 23.0637 20.5181 23.0637 17.7516C23.0637 14.9852 20.7682 12.7407 17.9389 12.7407C15.1096 12.7407 12.8141 14.9852 12.8141 17.7516C12.8141 20.5181 15.1096 22.7625 17.9389 22.7625ZM22.4942 24.281H20.5333C19.7432 24.6359 18.8642 24.8378 17.9389 24.8378C17.0136 24.8378 16.1381 24.6359 15.3444 24.281H13.3835C10.8674 24.281 8.82812 26.2749 8.82812 28.7351V29.2919C8.82812 30.2141 9.59329 30.9622 10.5364 30.9622H25.3413C26.2845 30.9622 27.0496 30.2141 27.0496 29.2919V28.7351C27.0496 26.2749 25.0104 24.281 22.4942 24.281Z"
        fill="#E5EAFF"
      />
      <path
        d="M34.4102 34.7271H58.0589"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5399 42.6101C56.4582 42.6101 59.6345 39.4338 59.6345 35.5155C59.6345 31.5973 56.4582 28.4209 52.5399 28.4209C48.6217 28.4209 45.4453 31.5973 45.4453 35.5155C45.4453 39.4338 48.6217 42.6101 52.5399 42.6101Z"
        fill="#E5EAFF"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5684 35.1598L51.6369 37.2283L55.7739 33.0913"
        stroke="#417CFF"
        strokeWidth="1.5"
      />
      <path
        d="M34.4102 49.3311H58.0589"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5223 57.2141C41.4406 57.2141 44.617 54.0378 44.617 50.1195C44.617 46.2013 41.4406 43.0249 37.5223 43.0249C33.6041 43.0249 30.4277 46.2013 30.4277 50.1195C30.4277 54.0378 33.6041 57.2141 37.5223 57.2141Z"
        fill="#E5EAFF"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8672 47.4644L40.1778 52.7749"
        stroke="#417CFF"
        strokeWidth="1.5"
      />
      <path
        d="M40.1777 47.4644L34.8671 52.7749"
        stroke="#417CFF"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};

export default PreferenceIcon;
