import React from "react";
import { Alert, Snackbar, Box } from "@mui/material";
import SnackbarUtil from "../utils/SnackbarUtil";
import SnackbarErrorBlock from "./intervention/SnackbarErrorBlock";

const CAIHSnackbar = () => {
  const [snackbar, setSnackbar] = SnackbarUtil.useSnackbar();
  const { isOpen, message, type, additionalComponent } = snackbar;

  const closeSnackbar = () => {
    setSnackbar({
      isOpen: false,
      message: "",
      type: undefined,
      additionalComponent: false
    });
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={additionalComponent ? null : 3000}
      onClose={() => closeSnackbar()}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert severity={type}>
        {additionalComponent ? (
          <Box>
            {message}
            <SnackbarErrorBlock />
          </Box>
        ) : (
          <>{message}</>
        )}
      </Alert>
    </Snackbar>
  );
};

export default CAIHSnackbar;
