import React from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Typography, useMediaQuery, Paper, Grid } from "@mui/material";
import ShieldIcon from "../../icons/ShieldIcon";
import PadlockIcon from "../../icons/PadlockIcon";

const ConsentNote = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      sx={{
        borderRadius: "8px",
        backgroundColor: "#eef0fb",
        paddingTop: isMobile ? "16px" : "32px",
        paddingBottom: isMobile ? "16px" : "32px",
        paddingLeft: isMobile ? "16px" : "64px",
        paddingRight: isMobile ? "16px" : "64px",
      }}
    >
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ marginBottom: isMobile ? "18px" : "0px" }}
        >
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "flex-start"}
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <ShieldIcon
              style={{ height: "auto", width: "55px", fill: "transparent" }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "cobalt.ultramarine",
                marginLeft: isMobile ? "0px" : "12px",
                marginTop: isMobile && "12px",
              }}
            >
              {t("consents:footer.leftItem")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "flex-start"}
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <PadlockIcon
              style={{ height: "auto", width: "55px", fill: "transparent" }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "cobalt.ultramarine",
                marginLeft: isMobile ? "0px" : "12px",
                marginTop: isMobile && "12px",
              }}
            >
              {t("consents:footer.rightItem")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConsentNote;
