import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";
import { theme } from "da-design-system";

const CAIHTheme = createTheme(
  theme,
  {
    palette: {
      cobalt: {
        focused: "#3363CC",
      },
    },
    shadows: [
      ...theme.shadows,
      "0px 0px 15px 0px rgba(0, 0, 45, 0.04), 0px 0px 0px 0px rgba(0, 0, 46, 0.06)",
    ],
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&.Mui-expanded::before": {
              opacity: 1,
            },
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "CAIHLite" },
            style: {
              ...theme.components.MuiButton.styleOverrides.sizeSmall,
              ...theme.components.MuiButton.styleOverrides.outlined,
              height: 32,
              ":hover": {
                backgroundColor: theme.palette.cobalt.bleuDigital10,
                color: theme.palette.cobalt.bleuDigital100,
              },
              ":active": {
                backgroundColor: theme.palette.cobalt.statesActive,
                color: theme.palette.cobalt.white,
              },
              ":focus": {
                backgroundColor: theme.palette.cobalt.statesFocused,
                color: theme.palette.cobalt.white,
              },
              ":disabled": {
                backgroundColor: theme.palette.cobalt.grey10,
                color: theme.palette.cobalt.grey30,
                borderColor: theme.palette.cobalt.grey10,
              },
            },
          },
          {
            props: { variant: "CAIHLiteContained" },
            style: {
              ...theme.components.MuiButton.styleOverrides.sizeSmall,
              ...theme.components.MuiButton.styleOverrides.contained,
              height: 32,
              ":hover": {
                backgroundColor: theme.palette.cobalt.bleuDigital10,
                color: theme.palette.cobalt.bleuDigital100,
              },
              ":active": {
                backgroundColor: theme.palette.cobalt.statesActive,
                color: theme.palette.cobalt.white,
              },
              ":focus": {
                backgroundColor: theme.palette.cobalt.statesFocused,
                color: theme.palette.cobalt.white,
              },
              ":disabled": {
                backgroundColor: theme.palette.cobalt.grey10,
                color: theme.palette.cobalt.grey30,
                borderColor: theme.palette.cobalt.grey10,
              },
            },
          },
          {
            props: { variant: "CAIHConsentButton" },
            style: {
              ...theme.components.MuiButton.styleOverrides.sizeSmall,
              ...theme.components.MuiButton.styleOverrides.outlined,
              height: 32,
              ":hover": {
                backgroundColor: theme.palette.cobalt.bleuDigital10,
                color: theme.palette.cobalt.bleuDigital100,
              },
              ":disabled": {
                borderColor: theme.palette.cobalt.grey10,
                backgroundColor: theme.palette.cobalt.grey10,
                color: theme.palette.cobalt.grey30,
              },
              "&.selected": {
                color: theme.palette.cobalt.white,
                backgroundColor: "#2F8375",
                borderColor: "#2F8375",
              },
              "&.refused": {
                color: theme.palette.cobalt.white,
                backgroundColor: "#D7376C",
                borderColor: "#D7376C",
              },
            },
          },
          {
            props: { variant: "delta" },
            style: {
              textTransform: "none",
              color: theme.palette.cobalt.ultramarine,
              "&:hover": {
                background: theme.palette.cobalt.bleuDigital05,
              },
              "&:focus": {
                background: theme.palette.cobalt.focused,
              },
            },
          },
        ],
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            opacity: 1,
            backgroundColor: theme.palette.cobalt.userBlue,
            color: theme.palette.cobalt.white,
            padding: "38px",
            boxShadow: "0px 4px 8px rgba(102,109,146,0.08)",
          },
          arrow: {
            color: theme.palette.cobalt.userBlue,
          },
          touch: {
            opacity: 1,
            backgroundColor: theme.palette.cobalt.userBlue,
            color: theme.palette.cobalt.white,
            padding: "38px",
          },
        },
      },
      MuiIconButton: {
        style: {
          "&.MuiIconButton-root": {
            width: "20px",
            height: "20px",
            position: "absolute",
            color: theme.palette.cobalt.ultramarine,
            [theme.breakpoints.up("md")]: {
              top: -10,
            },
          },
          "& label": {
            "& svg": {
              fontSize: "16px",
            },
          },
        },
      },
      MuiInputBase: {
        variants: [
          {
            props: { variant: "otp" },
            style: {
              width: 50,
              height: 65,
              borderRadius: "8px",
              [theme.breakpoints.down("md")]: {
                width: 36,
                height: 57,
                fontSize: 24,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "6px",
                "& fieldset": {
                  borderWidth: 1,
                },
                "&:hover fieldset": {
                  borderWidth: 1,
                },
                "&.Mui-focused fieldset": {
                  borderWidth: 1,
                },
              },
              "& input": {
                width: 16,
                height: 35,
                fontSize: 30,
                padding: "14px 17px",
                fontFamily: "Barlow, sans-serif",

                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                [theme.breakpoints.down("md")]: {
                  width: 14,
                  height: 35,
                  fontSize: 24,
                  padding: "10px 11px",
                },
              },
            },
          },
        ],
      },
    },
  },
  frFR
);

export default CAIHTheme;
