import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, Typography, Grid, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { consentProps } from "../../types/Consent";

export const linerConsentProps = PropTypes.shape({
  handleChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  checkedValues: PropTypes.arrayOf(consentProps).isRequired,
  selectedConsents: PropTypes.arrayOf(consentProps).isRequired,
  consentCount: PropTypes.number.isRequired,
});

const LinerConsent = ({
  consent,
  consentCount,
  handleChange,
  onClick,
  checkedValues,
  selectedConsents,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isSelected =
    checkedValues.includes(consent) && selectedConsents.includes(consent);
  const isRefused =
    !checkedValues.includes(consent) && selectedConsents.includes(consent);

  return (
    <Grid
      item
      container
      sm={12}
      justifyContent="center"
      alignItems="center"
      sx={
        consentCount > 1 && {
          borderBottom: "1px solid",
          borderBottomColor: "cobalt.grey10",
        }
      }
    >
      <Grid item xs={12} sm={6} md={7} lg={8} py={4} pl={2}>
        <Typography variant="body2">{consent.intentLabel}</Typography>
        {consent.intentDescription !== null &&
          consent.intentDescription !== "" && (
            <Typography
              variant="subtitle2"
              onClick={() => onClick(consent)}
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "cobalt.bleuDigital100",
              }}
            >
              En savoir plus
            </Typography>
          )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        md={5}
        lg={4}
        justifyContent="space-evenly"
        alignItems="center"
        pb={isMobile && 4}
      >
        <Button
          variant="CAIHConsentButton"
          className={isSelected && "selected"}
          onClick={() => handleChange(consent, "COMPLETED")}
        >
          Oui
        </Button>
        <Button
          variant="CAIHConsentButton"
          className={isRefused && "refused"}
          onClick={() => handleChange(consent, "REFUSED")}
          sx={{ marginLeft: isMobile ? "16px" : "0px" }}
        >
          Non
        </Button>
      </Grid>
    </Grid>
  );
};

export default LinerConsent;

LinerConsent.propTypes = {
  ...consentProps,
  ...linerConsentProps,
};
