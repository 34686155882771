import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import FieldItem from "./FieldItem";
import CAIHSelect from "./CAIHSelect";
import PatientData from "../../utils/PatientDataUtil";
import FieldController from "./FieldController";

const FormSkeleton = ({ variant, showFreeTextField }) => {
  const { control, clearErrors } = useFormContext();
  const { fields } = PatientData;

  useEffect(() => clearErrors(), [clearErrors]);

  return (
    <Grid container flexDirection="row" flexWrap="wrap" spacing={3}>
      {fields[variant].map((field) => {
        if (
          !showFreeTextField &&
          field.type === "text" &&
          field.name === "complementaryReason"
        ) {
          return null;
        }
        if (field.item === "select") {
          return (
            <Grid item xs={field.xs} key={field.name}>
              <FieldItem label={field.label}>
                <CAIHSelect
                  choices={field.choices}
                  placeholder={field.placeholder}
                  name={field.name}
                />
              </FieldItem>
            </Grid>
          );
        }
        return (
          <Grid item xs={field.xs} key={field.name}>
            <FieldItem label={field.label}>
              <FieldController
                name={field.name}
                control={control}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
              />
            </FieldItem>
          </Grid>
        );
      })}
    </Grid>
  );
};

FormSkeleton.propTypes = {
  variant: PropTypes.string.isRequired,
  showFreeTextField: PropTypes.bool.isRequired,
};

export default FormSkeleton;
