import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, useMediaQuery, Typography, Button } from "@mui/material";
import SuccessImg from "../../images/validation_formulaire.svg";
import ModalUtil from "../../utils/ModalUtil";

const GDPRSuccessModal = props => {
  const setModal = ModalUtil.useModal()[1];
  let { consentParams } = props;
  const { medicalConsents } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  consentParams = { ...consentParams, medicalConsents };

  const handleNextStep = () => {
    setModal({
      isOpen: false,
      title: "",
      content: null,
      backdropReason: "click"
    });

    history.push("/consent/intervention", consentParams);
  };

  if (isMobile) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            color: "cobalt.ultramarine",
            marginBottom: "16px"
          }}
        >
          {t("newConsent:GDPRModal:successMessage")}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "cobalt.ultramarine",
            textAlign: "center"
          }}
        >
          {t("newConsent:GDPRModal:emailConfirmation")}
        </Typography>
        <img
          src={SuccessImg}
          alt="success_image"
          style={{ width: 130, height: "auto", marginTop: "22px" }}
        />
        <Button fullWidth onClick={handleNextStep} sx={{ marginTop: "32px" }}>
          {t("common:next")}
        </Button>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <img
        src={SuccessImg}
        alt="success_image"
        style={{ width: 190, height: "auto" }}
      />
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          color: "cobalt.ultramarine",
          padding: "24px 0px"
        }}
      >
        {t("newConsent:GDPRModal:successMessage")}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: "cobalt.ultramarine",
          margin: "0px -8px"
        }}
      >
        {t("newConsent:GDPRModal:emailConfirmation")}
      </Typography>
      <Button fullWidth onClick={handleNextStep} sx={{ marginTop: "32px" }}>
        {t("common:next")}
      </Button>
    </Grid>
  );
};

GDPRSuccessModal.propTypes = {
  consentParams: PropTypes.shape({
    ipp: PropTypes.string.isRequired,
    idClinic: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    clinicName: PropTypes.string.isRequired,
    clinicLogo: PropTypes.string.isRequired,
    birthdate: PropTypes.instanceOf(Date).isRequired,
    legalRepresentative: PropTypes.string.isRequired,
    clinicGHTLogo: PropTypes.string.isRequired,
    clinicLegalNotice: PropTypes.string.isRequired,
    clinicGtu: PropTypes.string.isRequired,
    hasBothConsentType: PropTypes.bool.isRequired
  }).isRequired,
  medicalConsents: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default GDPRSuccessModal;
