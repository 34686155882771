import React from "react";
import PropTypes from "prop-types";
import { withTranslation, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  ButtonBase,
  Button,
  Box,
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Container
} from "@mui/material";
import { CobaltIcon } from "da-design-system";
import Modal from "./Modal";
import CAIHSnackbar from "./CAIHSnackbar";
import AuthService from "../services/AuthService";

const Layout = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = history;
  const { children, noHeader } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    AuthService.logout();
    history.push("/");
  };

  return (
    <>
      <CssBaseline />
      {!noHeader && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <ButtonBase
                sx={{ flexShrink: 1 }}
                onClick={() => {
                  if (location.pathname !== "/") {
                    history.push("/");
                  }
                }}
              >
                {AuthService.isAuthenticated() && (
                  <Typography variant="h6">
                    {t("layout:title.patient")}
                  </Typography>
                )}
              </ButtonBase>
              {AuthService.isAuthenticated() && (
                <Box sx={{ flexGrow: 1 }}>
                  <Button
                    sx={{ flexShrink: 1, float: "right" }}
                    onClick={handleClick}
                    startIcon={<CobaltIcon name="power" />}
                    color="inherit"
                  >
                    {!isMobile && t("layout:disconnect")}
                  </Button>
                </Box>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      )}
      <Toolbar />
      <Container maxWidth="md" sx={{ marginTop: "32px" }}>
        {children}
      </Container>
      <Modal />
      <CAIHSnackbar />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  noHeader: PropTypes.bool
};

Layout.defaultProps = {
  children: null,
  noHeader: false
};

export default withTranslation()(Layout);
