import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { isValid } from "date-fns";
import {
  Box,
  TextField,
  useMediaQuery,
  Typography,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CobaltIcon } from "da-design-system";

function CAIHDatepickerLite(props) {
  const { t } = useTranslation();
  const { onChangeFunc, selectedDate } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isSetted, setIsIsetted] = useState(false);
  const [open, setOpen] = useState(false);
  const setValue = onChangeFunc;

  if (selectedDate === "" && isSetted === false) {
    setValue(null);
    setIsIsetted(true);
  }

  const { fullWidth, disabled, required, disabledPart } = props;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "cobalt.ultramarine" }}
        >
          {t("consentLogin:birthdate.placeholder")}
        </Typography>
        <DesktopDatePicker
          onAccept={() => setOpen(false)}
          open={open}
          maxDate={
            disabledPart === "FUTURE" || disabledPart === "BOTH"
              ? Date.now()
              : null
          }
          disableOpenPicker
          disableHighlightToday
          inputProps={{
            placeholder: "JJ / MM / AAAA",
          }}
          id="birthdate-lite"
          value={selectedDate}
          onChange={(date) => {
            setValue(date);
            setIsIsetted(true);
          }}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              sx={{
                "& input": {
                  height: "16px",
                },
                "& label": {
                  marginBottom: "16px",
                },
                "& svg": {
                  color: "cobalt.ultramarine",
                },
              }}
              required={required}
              disabled={disabled}
              fullWidth={fullWidth || isMobile}
              margin="normal"
              helperText={
                selectedDate !== null && !isValid(selectedDate)
                  ? t("form:error.date")
                  : ""
              }
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setOpen(!open)}>
                      <CobaltIcon
                        name="calendar"
                        style={{
                          fontSize: "16px",
                          color: theme.palette.cobalt.ultramarine,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
    </ClickAwayListener>
  );
}

CAIHDatepickerLite.propTypes = {
  onChangeFunc: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabledPart: PropTypes.oneOf(["NONE", "BOTH", "FUTURE", "PAST"]),
};

CAIHDatepickerLite.defaultProps = {
  selectedDate: null,
  disabled: false,
  required: false,
  fullWidth: false,
  disabledPart: "NONE",
};

export default CAIHDatepickerLite;
