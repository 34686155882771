import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper, Box, useMediaQuery } from "@mui/material";
import QuestionIcon from "../../icons/QuestionIcon";

const InterventionNote = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      elevation={0}
      sx={{
        padding: isMobile ? "10px 16px" : "10px 32px",
        borderRadius: "8px",
        backgroundColor: "#eef0fb",
        color: "cobalt.ultramarine",
        marginTop: "16px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Grid item>
          <QuestionIcon
            style={{
              width: isMobile ? 32.8 : 40,
              height: isMobile ? 50 : 60,
              marginRight: isMobile ? 16 : 24,
              fill: "transparent",
            }}
          />
        </Grid>
        <Grid item>
          <Box
            sx={{
              fontFamily: "'Barlow', sans-serif",
              fontSize: "14px",
              lineHeight: 1.2,
            }}
          >
            {t(
              "Si vous avez des questions ou des réserves, veuillez s'il vous plaît vous rapprocher de votre établissement."
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InterventionNote;
