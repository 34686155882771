import React, { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PatientDataUtil from "../../utils/PatientDataUtil";
import LogoLayout from "../../components/LogoLayout";
import FormSkeleton from "../../components/legalRepresentativeForm/FormSkeleton";
import LegalTextBlock from "../../components/legalRepresentativeForm/LegalTextBlock";
import useStepValidation from "../../hooks/patientInformation/useStepValidation";
import PatientService from "../../services/PatientService";
import AuthContext from "../../context/authContext";
import ConsentSuccessIllustration from "../../images/success-consents-illus.png";
import ConsentFailureIllustration from "../../images/failure-consents-illus.png";

const PatientInformationsForm = () => {
  const history = useHistory();
  const [isRPSingle, setIsRPSingle] = useState(false);
  const [isUpdated, setIsUpdated] = useState(null);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(null);

  const { isAuthenticated, currentToken } = useContext(AuthContext);

  const { PatientValidationSchema } = PatientDataUtil;
  const { updatePatientLegalRepresentatives } = PatientService;
  const methods = useForm({
    resolver: yupResolver(PatientValidationSchema),
    context: [],
    mode: "onChange",
    defaultValues: {
      legalRepresentative1: {},
      legalRepresentative2: {},
      reason: "",
      complementaryReason: "",
    },
  });
  const {
    watch,
    reset,
    trigger,
    formState: { errors },
  } = methods;

  const form = watch();

  const { isLoading, mutate: updateInfos } = useMutation({
    mutationKey: "updateLegalRepresentative",
    mutationFn: ({ body }) => updatePatientLegalRepresentatives(body),
    onSettled: () => {
      setIsUpdated(true);
      reset();
    },
    onSuccess: () => {
      setIsUpdateSuccessful(true);
    },
    onError: () => {
      setIsUpdateSuccessful(false);
    },
    enabled: false,
  });

  const { isStepValid } = useStepValidation(errors, form, isRPSingle, trigger);

  if (!isAuthenticated || currentToken !== history.location.hash.substring(1)) {
    history.push("/");
    return null;
  }

  const onSubmit = () => {
    const getRepresentativeList = (lg1, lg2) =>
      isRPSingle ? [{ ...lg1 }] : [{ ...lg1 }, { ...lg2 }];

    const getFinalBody = (
      legalRepresentativeList,
      reason,
      complementaryReason
    ) =>
      isRPSingle
        ? {
            legalRepresentativeList,
            jeton: currentToken,
            reasonAbsenceLegalRep:
              reason === "Autre" ? complementaryReason : reason,
          }
        : {
            legalRepresentativeList,
            jeton: currentToken,
          };

    // Ici récupérer les infos patient probablement
    const {
      reason,
      complementaryReason,
      legalRepresentative1,
      legalRepresentative2,
    } = form;

    const legalRepresentativeList = getRepresentativeList(
      legalRepresentative1,
      legalRepresentative2
    );

    const body = getFinalBody(
      legalRepresentativeList,
      reason,
      complementaryReason
    );

    return updateInfos({
      body,
    });
  };

  return (
    <LogoLayout withFooter={false}>
      <Grid container justifyContent="center" alignItems="center">
        {!isUpdated && (
          <Typography variant="h3" sx={{ mb: 3 }}>
            Saisie des coordonnées signataires
          </Typography>
        )}
        <Card
          sx={{
            p: 4,
          }}
        >
          {!isUpdated ? (
            <FormProvider {...methods}>
              <form>
                <Typography
                  variant="body2"
                  sx={{ mb: 3, color: "cobalt.lynch" }}
                >
                  Saisissez des coordonnées signataires
                </Typography>
                <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
                  Signataire 1
                </Typography>
                <FormSkeleton variant="RP1" />
                <FormGroup component="div" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setIsRPSingle(!isRPSingle)}
                        checked={isRPSingle}
                      />
                    }
                    label="Ne pas déclarer un deuxième parent"
                    sx={{ my: 3, ml: 0.5 }}
                  />
                </FormGroup>

                {isRPSingle ? (
                  <FormSkeleton
                    variant="singleRP"
                    showFreeTextField={form.reason === "Autre"}
                  />
                ) : (
                  <>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                      Signataire 2
                    </Typography>
                    <FormSkeleton variant="RP2" />
                  </>
                )}
                <Grid container flexDirection="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!isStepValid || isLoading}
                    sx={{ mt: 5 }}
                  >
                    Envoyer
                  </Button>
                  {isRPSingle && <LegalTextBlock />}
                </Grid>
              </form>
            </FormProvider>
          ) : (
            <Stack justifyContent="center" alignItems="center" minWidth="80vh">
              <img
                src={
                  isUpdateSuccessful
                    ? ConsentSuccessIllustration
                    : ConsentFailureIllustration
                }
                alt="Success consentement"
                style={{ maxWidth: "380px", height: "auto" }}
              />
              <Typography variant="h5" sx={{ mt: 3 }}>
                {isUpdateSuccessful
                  ? "Les coordonnées ont bien été enregistrées"
                  : "Une erreur est survenue"}
              </Typography>
              <Typography variant="body1" sx={{ mt: 3 }}>
                {isUpdateSuccessful
                  ? "Vous recevrez bientôt un email vous invitant à signer votre consentement"
                  : "Vos coordonnées n'ont pas pu être enregistrées"}
              </Typography>
            </Stack>
          )}
        </Card>
      </Grid>
    </LogoLayout>
  );
};

export default PatientInformationsForm;
