import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { CobaltIcon } from "da-design-system";
import LogoLayout from "../../components/LogoLayout";
import ConsentContent from "../../components/consent/ConsentContent";
import CAIHIntervention from "../../components/CAIHIntervention";
import PatientConsentLoading from "../../components/consent/PatientConsentLoading";
import InterventionNote from "../../components/intervention/InterventionNote";
import ConsentService from "../../services/ConsentService";
import ClinicService from "../../services/ClinicService";

const InterventionListing = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = props;
  let { state } = location;
  const [clinicLogo, setClinicLogo] = useState("");
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicGtu, setClinicGtu] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [consentContent, setConsentContent] = useState([]);
  const [filteredUserConsents, setFilteredUserConsents] = useState([]);
  const [hasMultipleInterventions, setHasMultipleInterventions] = useState(
    false
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const styles = {
    birthDateLabel: {
      fontSize: isMobile ? "14px" : "18px",
      lineHeight: 1.2,
      color: "cobalt.lynch",
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 500,
    },
  };

  if (state === undefined) {
    state = {
      ipp: undefined,
      idClinic: undefined,
      name: undefined,
      clinicLogo: undefined,
      birthdate: undefined,
      legalRepresentative: undefined,
      clinicLegalNotice: undefined,
      clinicGtu: undefined,
      hasBothConsentType: undefined,
      medicalConsents: undefined,
    };

    history.push("/");
  }

  useEffect(() => {
    const { ipp, idClinic } = state;

    setClinicLegalNotice(state.clinicLegalNotice);
    setClinicGtu(state.clinicGtu);
    setClinicLogo(state.clinicLogo);

    ClinicService.getClinicMedicalInformationNote(state.idClinic)
      .then((cinResponse) => {
        const { data } = cinResponse;

        const MedicalInfoNote = JSON.parse(data[0].value);
        setConsentContent(MedicalInfoNote.description);
      })
      .catch((error) => console.log(error));

    const handleMedicalConsents = (consents) => {
      const filteredMedConsents = [];
      // ! POC -- Enleve les consentements déjà validés par l'utilisateur courant
      // Evite de reafficher des consentements deja signes par lui si le consentement a besoin de 2 representants legaux et qu'il est le 1er a signer
      const previousValidationOnSession = state?.validatedConsents ?? null;
      const userMedConsents = consents.filter((consent) => {
        return !previousValidationOnSession
          ? consent.intentType === "MEDICAL" && consent.status === "REQUESTED"
          : consent.intentType === "MEDICAL" &&
              consent.status === "REQUESTED" &&
              previousValidationOnSession.some((i) => i.id !== consent.id);
      });

      userMedConsents.forEach((userMedConsent) => {
        const inListObject = filteredMedConsents.filter(
          (filteredMedConsent) =>
            filteredMedConsent.interventionId === userMedConsent.interventionId
        );

        if (inListObject.length === 0) {
          filteredMedConsents.push({
            clinicGtu: state.clinicGtu,
            clinicLogo: state.clinicLogo,
            clinicLegalNotice: state.clinicLegalNotice,
            ipp: state.ipp,
            patientId: userMedConsent.patientId,
            healthFacilityId: userMedConsent.healthFacilityId,
            name: state.name,
            birthdate: state.birthdate,
            legalRepresentative: state.legalRepresentative,
            interventionId: userMedConsent.interventionId,
            interventionDate: userMedConsent.interventionDate,
            consentsList: [
              {
                consentId: userMedConsent.id,
                title: userMedConsent.medicalConsentTemplateTitle,
                status: userMedConsent.status,
              },
            ],
          });
        } else {
          const item = filteredMedConsents.filter(
            (filteredMedConsent) =>
              filteredMedConsent.interventionId ===
              inListObject[0].interventionId
          );
          item[0].consentsList.push({
            title: userMedConsent.medicalConsentTemplateTitle,
            consentId: userMedConsent.id,
          });
        }
      });

      if (filteredMedConsents.length > 1) {
        setHasMultipleInterventions(true);
      }
      setFilteredUserConsents(filteredMedConsents);
    };

    // Si on arrive directement sur cette page, on fait une requête pour récupérer les consentements
    if (!state.hasBothConsentType) {
      ConsentService.getAllMedicalConsents(ipp, idClinic)
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          const consents = data;

          const requestedConsents = consents.filter(
            (consent) =>
              consent.intentType === "MEDICAL" && consent.status === "REQUESTED"
          );

          handleMedicalConsents(requestedConsents);
        })
        .catch((error) => console.log(error));
    } else {
      // Sinon on traite ceux transmis par le state de la modale
      setIsLoading(false);
      handleMedicalConsents(state.medicalConsents);
    }
  }, [state]);

  return (
    <LogoLayout
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
      containerSize="md"
    >
      {/* Bloc de titre */}
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justifyContent="flex-start"
        alignItems={isMobile ? "flex-start" : "center"}
        sx={{
          marginBottom: isMobile ? "24px" : "12px",
        }}
      >
        <Box mr={3}>
          <Typography variant="h1" sx={{ textTransform: "capitalize" }}>
            {`${state.name}`}
          </Typography>
        </Box>
        {state.birthdate !== undefined && (
          <Box sx={{ ...styles.birthDateLabel }}>
            {`Né(e) le ${format(new Date(state.birthdate), "dd.MM.yyyy")}`}
          </Box>
        )}
      </Grid>
      {/* Note d'information */}
      {consentContent === null ||
      (consentContent.length === 1 && consentContent[0] === "") ? (
        <Box />
      ) : (
        <Box>
          <ConsentContent
            isLoading={isLoading}
            title={{
              titleLabel: t("intervention:infoNote"),
              iconLeft: (
                <CobaltIcon
                  name="alert-circle"
                  style={{
                    color: theme.palette.cobalt.ultramarine,
                    marginRight: "12px",
                    fontSize: "17px",
                  }}
                />
              ),
            }}
            content={consentContent}
          />
        </Box>
      )}
      {/* Interventions */}
      {isLoading ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={isMobile ? 1.5 : 2}
        >
          <PatientConsentLoading fullWidth />
          <PatientConsentLoading fullWidth />
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={isMobile ? 1.5 : 2}
        >
          {filteredUserConsents.map((consent) => (
            <CAIHIntervention
              consent={consent}
              key={consent.interventionId}
              legalRepresentative={state.legalRepresentative}
              hasMultipleInterventions={hasMultipleInterventions}
            />
          ))}
        </Grid>
      )}
      {/* Footer Note */}

      <Box mb={5}>
        <InterventionNote />
      </Box>
      {/* Fin de contenu */}
    </LogoLayout>
  );
};

InterventionListing.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      ipp: PropTypes.string.isRequired,
      idClinic: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      clinicLogo: PropTypes.string.isRequired,
      birthdate: PropTypes.instanceOf(Date).isRequired,
      legalRepresentative: PropTypes.string.isRequired,
      clinicLegalNotice: PropTypes.string.isRequired,
      clinicGtu: PropTypes.string.isRequired,
      hasBothConsentType: PropTypes.bool.isRequired,
      medicalConsents: PropTypes.array,
    }),
  }).isRequired,
};

export default InterventionListing;
