import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useField } from "@formiz/core";
import { isValid } from "date-fns";
import {
  Box,
  useMediaQuery,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CobaltIcon } from "da-design-system";

function CAIHDatepicker(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { setValue, value } = useField(props);

  const { fullWidth, disabled, required, disabledPart } = props;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <Typography variant="body1">
          {t("consentLogin:birthdate.placeholder")}
        </Typography>

        <DesktopDatePicker
          open={open}
          onAccept={() => setOpen(false)}
          disableHighlightToday
          onChange={(date) => {
            setValue(date);
          }}
          maxDate={
            disabledPart === "FUTURE" || disabledPart === "BOTH"
              ? Date.now()
              : undefined
          }
          value={value || null}
          disableOpenPicker
          inputProps={{
            placeholder: "JJ / MM / AAAA",
          }}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              required={required}
              disabled={disabled}
              id="birthdate"
              sx={{
                "& input": {
                  height: "16px",
                  width: "261px",
                },
                "& label": {
                  marginBottom: "16px",
                },
                "& svg": {
                  color: "cobalt.ultramarine",
                },
              }}
              fullWidth={fullWidth || isMobile}
              helperText={
                !isValid(value) && value !== null ? t("form:error.date") : ""
              }
              margin="normal"
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setOpen(!open)}>
                      <CobaltIcon
                        name="calendar"
                        style={{
                          fontSize: "16px",
                          color: theme.palette.cobalt.ultramarine,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
    </ClickAwayListener>
  );
}

CAIHDatepicker.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabledPart: PropTypes.oneOf(["NONE", "BOTH", "FUTURE", "PAST"]),
};

CAIHDatepicker.defaultProps = {
  disabled: false,
  required: false,
  fullWidth: false,
  disabledPart: "NONE",
};

export default CAIHDatepicker;
