import AuthService from "../services/AuthService";
import UrlUtils from "../utils/UrlUtils";
import { PUBLIC_ROUTING, PRIVATE_PATIENT_ROUTING } from "./routes";

export default class Resolver {
  static resolve = (routeKey, params = {}) => {
    const allRoutes = [...PUBLIC_ROUTING, ...PRIVATE_PATIENT_ROUTING];
    const route = allRoutes.find(r => r.key === routeKey);

    if (route) {
      // Si la route a été trouvée dans PUBLIC_ROUTING ou PRIVATE_ROUTING
      let { path } = route;
      const queryParams = {};
      Object.keys(params).forEach(paramKey => {
        if (path.indexOf(`:${paramKey}`) !== -1) {
          // On remplace le path params
          path = path.replace(`:${paramKey}`, params[paramKey]);
        } else {
          // Si le paramètre n'est pas un path param, on le stocke dans le queryParams
          queryParams[paramKey] = params[paramKey];
        }
      });
      // Check si tous les path params ont été remplacé
      if (path.indexOf(":") === -1) {
        if (Object.values(queryParams).length) {
          // Rajoute le param au path
          path += `?${UrlUtils.createQueryParams(queryParams)}`;
        }
        return path;
      }
    }

    // Si la route n'est pas identifiée, redirige vers la Home
    return Resolver.resolve(
      AuthService.isAuthenticated ? "login" : "consents",
      params
    );
  };
}
