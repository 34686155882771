import { useContext, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import PatientService from "../../services/PatientService";
import AuthContext from "../../context/authContext";

const useTokenAuth = (hash) => {
  const { isAuthenticated, setCurrentToken, currentToken } = useContext(
    AuthContext
  );
  const [token, setToken] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [healthFacilityId, setHealthFacilityId] = useState(null);
  const [localIpp, setLocalIpp] = useState(null);
  const [globalIntentType, setGlobalIntentType] = useState(null);

  useEffect(() => {
    const extractAPIToken = (hash) => {
      const tokenWithoutHash = hash.substring(1).split("&")[0];
      return setToken(tokenWithoutHash);
    };

    return extractAPIToken(hash);
  }, [hash]);

  const { isFetching } = useQuery({
    queryKey: ["patientByToken", token],
    retry: 0,
    queryFn: () =>
      PatientService.getPatientByToken(hash.substring(1).split("&")[0]),
    onSettled: () => setToken(hash.substring(1).split("&")[0]),
    onSuccess: (res) => {
      setIsTokenValid(true);
      setGlobalIntentType(res.data.intentType);
      setCurrentToken(hash.substring(1).split("&")[0]);
      setHealthFacilityId(res.data.healthFacilityId);
      setLocalIpp(res.data.localIpp);
    },
    onError: () => setIsTokenValid(false),
    enabled: currentToken !== token || (!isAuthenticated && !isTokenValid),
  });

  return {
    isTokenValid,
    isFetching,
    healthFacilityId,
    localIpp,
    token,
    globalIntentType,
  };
};

useTokenAuth.propTypes = {
  hash: PropTypes.string.isRequired,
};
export default useTokenAuth;
