import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Paper,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import { CobaltIcon } from "da-design-system";
import LogoLayout from "../../components/LogoLayout";
import ConsentSuccessIllustration from "../../images/success-consents-illus.png";
import InterventionStep from "../../components/intervention/InterventionStep";
import ConsentService from "../../services/ConsentService";
import SnackbarUtil from "../../utils/SnackbarUtil";

const InterventionSignedListing = (props) => {
  const { t } = useTranslation();
  const { location } = props;
  const history = useHistory();
  let { state } = location;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [consents, setConsents] = useState([]);
  const [rejectedConsents, setRejectedConsents] = useState([]);
  const [hasRejectedConsents, setHasRejectedConsents] = useState(false);
  const [localIpp, setLocalIpp] = useState("");
  const [healthFacilityId, setHealthFacilityId] = useState("");
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicGtu, setClinicGtu] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);
  const setSnackbar = SnackbarUtil.useSnackbar()[1];
  const [hasMultipleInterventions, setHasMultipleInterventions] = useState(
    false
  );

  if (state === undefined) {
    state = {
      healthFacilityId: undefined,
      ipp: undefined,
      clinicLogo: undefined,
      clinicGtu: undefined,
      clinicLegalNotice: undefined,
      legalRepresentative: undefined,
      validatedConsents: [],
      name: undefined,
      birthdate: undefined,
      rejectedConsents: undefined,
    };
    history.push("/");
  }

  const setInitialData = useCallback(() => {
    setConsents(state.validatedConsents);
    setRejectedConsents(state.rejectedConsents);
    setHasRejectedConsents(state.rejectedConsents.length > 0);
    setLocalIpp(state.ipp);
    setHealthFacilityId(state.healthFacilityId);
    setClinicGtu(state.clinicGtu);
    setClinicLogo(state.clinicLogo);
    setClinicLegalNotice(state.clinicLegalNotice);
    setHasMultipleInterventions(state.hasMultipleInterventions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const fromb64toBytes = (b64) => {
    const binaryString = window.atob(b64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i += 1) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    return bytes;
  };

  const generateDownloadLink = (consent, title) => {
    ConsentService.downloadSignedConsent(consent, localIpp, healthFacilityId)
      .then((response) => {
        const pdfData = response.data;

        const blob = new Blob([fromb64toBytes(pdfData)], {
          type: "application/pdf",
        });

        const fileUrl = URL.createObjectURL(blob);
        const fileName = `${localIpp}_${title}.pdf`;

        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = fileName;
        link.click();
      })
      .catch((err) => {
        const { response } = err;
        const { status } = response;
        if (status === 400) {
          setSnackbar({
            isOpen: true,
            message: "Erreur lors du téléchargement du document",
            type: "error",
          });
        }
      });
  };

  const content = (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <img
        src={ConsentSuccessIllustration}
        alt="Success consentement"
        style={{ width: "240px", height: "auto" }}
      />
      <Box mt={3} sx={{ textAlign: "center" }}>
        <Typography variant="h3">
          {consents.length > 1
            ? "Consentements signés pour votre intervention"
            : "Consentement signé pour votre intervention"}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "12px",
          fontSize: "14px",
          lineHeight: 1.2,
          fontFamily: "'Barlow', sans-serif",
          color: "cobalt.ultramarine",
        }}
      >
        <Typography variant="subtitle1">
          {t("intervention:signed.confirmation")}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "24px",
          borderRadius: "8px",
          backgroundColor: "cobalt.bleuDigital05",
          color: "cobalt.bleuDigital100",
          fontSize: "18px",
          lineHeight: 1.2,
          fontWeight: 500,
          width: "100%",
        }}
        mt={3}
      >
        {consents.length >= 1 &&
          consents.map((consent) => (
            <Box
              key={consent.id}
              sx={{
                "&:not(:last-child)": {
                  marginBottom: "16px",
                },
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? "center" : "flex-start"}
                alignItems="center"
                flexWrap="nowrap"
              >
                <Box mr={1}>
                  <CobaltIcon
                    name="download"
                    style={{ textDecoration: "none" }}
                  />
                </Box>
                <Box
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() =>
                    generateDownloadLink(
                      consent.id,
                      consent.medicalConsentTemplateTitle
                    )
                  }
                >
                  <Typography
                    variant={isMobile ? "body2" : "subtitle1"}
                    sx={{ color: "cobalt.bleuDigital100" }}
                  >
                    {consent.medicalConsentTemplateTitle}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          ))}
        {rejectedConsents.length >= 1 &&
          rejectedConsents.map((rejectedConsent) => (
            <Box
              key={rejectedConsent.id}
              sx={{
                "&:not(:last-child)": {
                  marginBottom: "16px",
                },
                color: "cobalt.mandyText",
                textDecoration: "none",
                lineHeight: 1.2,
                fontWeight: 500,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? "center" : "flex-start"}
                alignItems="center"
                flexWrap="nowrap"
              >
                <CobaltIcon name="x" style={{ marginRight: "8px" }} />

                <Typography
                  variant={isMobile ? "body2" : "subtitle1"}
                  sx={{ color: "cobalt.mandyText" }}
                >
                  {rejectedConsent.title}
                </Typography>
              </Grid>
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          padding: "16px",
          marginTop: "16px",
          width: "100%",
          position: isMobile ? "absolute" : "inherit",
          bottom: 0,
        }}
      >
        {(hasMultipleInterventions || hasRejectedConsents) && (
          <Button
            fullWidth
            onClick={() => {
              history.push("/consent/intervention", {
                ...state,
                idClinic: state.healthFacilityId,
                hasBothConsentType: false,
              });
            }}
          >
            {t("common:next")}
          </Button>
        )}
      </Box>
    </Grid>
  );

  return (
    <LogoLayout
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
      headerStepper={<InterventionStep currentStep={2} />}
      containerSize="sm"
    >
      {isMobile ? (
        <Box>{content}</Box>
      ) : (
        <Paper sx={{ padding: "32px" }} elevation={0}>
          {content}
        </Paper>
      )}
    </LogoLayout>
  );
};

InterventionSignedListing.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      healthFacilityId: PropTypes.string.isRequired,
      ipp: PropTypes.string.isRequired,
      clinicLogo: PropTypes.string,
      clinicGtu: PropTypes.string,
      clinicLegalNotice: PropTypes.string,
      legalRepresentative: PropTypes.string,
      birthdate: PropTypes.instanceOf(Date).isRequired,
      name: PropTypes.string.isRequired,
      validatedConsents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          medicalConsentTemplateTitle: PropTypes.string.isRequired,
          contraliaTransactionId: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      rejectedConsents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          medicalConsentTemplateTitle: PropTypes.string.isRequired,
          contraliaTransactionId: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      hasMultipleInterventions: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default InterventionSignedListing;
