import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AskOTP from "../AskOTP";
import AuthService from "../../services/AuthService";
import consentService from "../../services/ConsentService";
import ModalUtil from "../../utils/ModalUtil";
import requestUtils from "../../utils/RequestUtils";

const OtpModal = props => {
  const { user } = props;

  const [currentUser, setCurrentUser] = useState(user);
  const setModal = ModalUtil.useModal()[1];
  const [canUseOtpInput, setCanUseOtpInput] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorType, setOtpErrorType] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => setCurrentUser(user), [user]);

  const inputStyles = {
    input: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.cobalt.grey20
        },
        "&:hover fieldset": {
          borderColor: theme.palette.cobalt.grey20
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.cobalt.ultramarine
        }
      },
      "& input": {
        color: theme.palette.cobalt.ultramarine
      }
    },
    otpError: {
      backgroundColor: theme.palette.cobalt.mandyBackground,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: `${theme.palette.cobalt.mandyText} !important`
        },
        "&:hover fieldset": {
          borderColor: theme.palette.cobalt.mandyText
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.cobalt.mandyText
        }
      },
      "& input": {
        color: theme.palette.cobalt.mandyText
      }
    },
    dirtyInput: {
      backgroundColor: theme.palette.cobalt.bleuDigital05,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: `${theme.palette.cobalt.ultramarine} !important`
        },
        "&:hover fieldset": {
          borderColor: theme.palette.cobalt.grey20
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.cobalt.ultramarine
        }
      },
      "& input": {
        color: theme.palette.cobalt.ultramarine
      }
    }
  };
  /**
   * @name getInputStyle
   * @description Renvoie un style en fonction de l'état des inputs et/ou du formulaire
   * @param {string} value Valeur de l'input pour savoir si dirty
   * @returns {string} Le nom de l'objet contenant le style à appliquer
   */

  const getInputStyle = value => {
    if (otpError) {
      return "otpError";
    }
    if (value !== "") {
      return "dirtyInput";
    }
    return "input";
  };

  const codeSize = 6;
  const getInputName = index => `number${index}`;
  const fields = new Array(codeSize)
    .fill()
    .map((v, index) => getInputName(index));

  const isLegalRepresentativeEmpty =
    Object.keys(currentUser.legalRepresentative).length === 0 &&
    currentUser.legalRepresentative.constructor === Object;

  const cantReachLegalRepresentative =
    currentUser.legalRepresentative.email === "" ||
    currentUser.legalRepresentative.mobileNumber === "" ||
    currentUser.legalRepresentative.email === undefined ||
    currentUser.legalRepresentative.mobileNumber === undefined ||
    currentUser.legalRepresentative.email === null ||
    currentUser.legalRepresentative.mobileNumber === null ||
    isLegalRepresentativeEmpty;

  const formik = useFormik({
    initialValues: fields.reduce(
      (values, key) => ({ ...values, [key]: "" }),
      {}
    ),
    validationSchema: Yup.object().shape(
      fields.reduce(
        (values, key) => ({
          ...values,
          [key]: Yup.number()
            .min(0)
            .lessThan(10)
            .required()
        }),
        {}
      )
    ),
    onSubmit: () => handleValidateCode()
  });

  const handleInputChange = useCallback(
    index => e => {
      e.preventDefault();

      const { value } = e.target;
      const inputName = e.target.name;
      const regex = /[0-9]/g;

      if (value.match(regex) && value.length) {
        if (errorText !== null) {
          setErrorText(null);
        }

        formik.setFieldValue(inputName, value.charAt(value.length - 1));
        const nextInput = document.querySelector(
          `input[name="${getInputName(index + 1)}"]`
        );

        if (value.length && nextInput) {
          nextInput.focus();
        }
      }
    },
    [formik, errorText]
  );

  const handleKeyDown = useCallback(
    index => e => {
      const invalidChars = ["-", "+", "e", "E"];

      // Si le caractère tapé est un caractère invalide
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }

      if (errorText !== null) {
        setErrorText(null);
      }

      const { value } = e.target;
      const newName = e.target.name;
      const lastInput = document.querySelector(
        `input[name="${getInputName(index - 1)}"]`
      );
      const nextInput = document.querySelector(
        `input[name="${getInputName(index + 1)}"]`
      );

      // TOP 38
      if (e.keyCode === 38) {
        e.preventDefault();

        if (value !== "") {
          const valueAsNumber = parseInt(value, 10);
          if (valueAsNumber < 9) {
            const upperValue = valueAsNumber + 1;
            formik.setFieldValue(newName, upperValue.toString());
          }
        } else {
          formik.setFieldValue(newName, 1);
        }
      }
      // RIGHT 39
      if (e.keyCode === 39 && nextInput !== null) {
        e.preventDefault();
        nextInput.focus();
      }
      // BOTTOM 40
      if (e.keyCode === 40) {
        e.preventDefault();
        if (value !== "") {
          const valueAsNumber = parseInt(value, 10);
          if (valueAsNumber > 0) {
            const upperValue = valueAsNumber - 1;
            formik.setFieldValue(newName, upperValue.toString());
          }
        } else {
          formik.setFieldValue(newName, 9);
        }
      }
      // LEFT 37
      if (e.keyCode === 37 && lastInput !== null) {
        e.preventDefault();
        lastInput.focus();
      }
      // DELETE 8
      if (e.keyCode === 8) {
        e.preventDefault();

        if (value !== "") {
          formik.setFieldValue(newName, "");
        }
        if (value === "" && lastInput !== null) {
          lastInput.focus();
        }
      }
    },
    [formik, errorText]
  );

  const generateOtp = () => {
    consentService
      .generateOtp(currentUser.localIpp, currentUser.healthFacilityId)
      .then(response => {
        const { status } = response;

        if (requestUtils.isSuccess(status)) {
          setCanUseOtpInput(true);

          // On focus sur la première input
          const firstInput = document.querySelector(
            `input[name="${getInputName(0)}"]`
          );
          firstInput.focus();
        }
      })
      .catch(() => {
        setCanUseOtpInput(false);
        setOtpErrorType("GENERAL");
        setOtpError(true);
      });
  };

  const closeModal = () => {
    setModal({
      isOpen: false,
      title: "",
      content: null,
      backdropReason: "click"
    });
  };

  const checkOtp = code => {
    setIsFetching(true);
    setOtpError(false);
    setOtpErrorType("");

    consentService
      .validateOtp(code, currentUser.localIpp, currentUser.healthFacilityId)
      .then(response => {
        const { status } = response;

        if (requestUtils.isSuccess(status)) {
          AuthService.setDataConnection(currentUser);
          closeModal();
          history.push("/consents");
        }
      })
      .catch(error => {
        setIsFetching(false);
        const { status, data } = error.response;
        const { errorMessage } = data;

        // On set le type d'erreur
        if (status === 404 && errorMessage === "CODE_NOT_FOUND") {
          setOtpErrorType(errorMessage);
        }
        if (status === 403 && errorMessage === "EXPIRED_CODE") {
          setOtpErrorType(errorMessage);
        }
        setOtpError(true);

        // Au bout de 5 secondes, on enlève les erreurs et reset le form
        setTimeout(() => {
          setOtpError(false);
          setOtpErrorType("");
          formik.resetForm();
        }, 3500);
      });
  };

  const handleValidateCode = () => {
    const { values } = formik;
    const code = new Array(codeSize)
      .fill()
      .map((v, index) => values[getInputName(index)])
      .join("");

    checkOtp(code);
  };

  const hidePhone = phoneNumber => phoneNumber.replace(/.(?=.{2,}$)/g, "X");

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={3}>
          {!canUseOtpInput ? (
            <Typography variant="subtitle1" sx={{ color: "cobalt.submarine" }}>
              {`${t("consentLogin:modal.phone.step1.infos")}
              ${hidePhone(
                !cantReachLegalRepresentative
                  ? currentUser.legalRepresentative.mobileNumber
                  : currentUser.mobileNumber
              )} :`}
            </Typography>
          ) : (
            <Typography variant="subtitle1" sx={{ color: "cobalt.submarine" }}>
              {`Rentrez le code reçu au 
              ${hidePhone(
                !cantReachLegalRepresentative
                  ? currentUser.legalRepresentative.mobileNumber
                  : currentUser.mobileNumber
              )} :`}
            </Typography>
          )}
        </Box>
        <Box mb={3}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {canUseOtpInput === false ? (
              <Grid item>
                <AskOTP generateOtpFunc={() => generateOtp()} />
              </Grid>
            ) : (
              <Grid item>
                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    {new Array(codeSize).fill().map((v, index) => (
                      <Grid item key={getInputName(index)}>
                        <TextField
                          label=""
                          name={getInputName(index)}
                          value={formik.values[getInputName(index)]}
                          onChange={handleInputChange(index)}
                          onKeyDown={handleKeyDown(index)}
                          onBlur={formik.handleBlur}
                          fullWidth={false}
                          type="number"
                          disabled={otpError}
                          InputProps={{
                            variant: "otp"
                          }}
                          sx={{
                            ...inputStyles[
                              getInputStyle(formik.values[getInputName(index)])
                            ]
                          }}
                          InputLabelProps={{ shrink: false }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  {otpError ? (
                    <Box sx={{ color: "cobalt.mandyText", mt: "12px" }}>
                      {t(`otp:errors.${otpErrorType}`)}
                    </Box>
                  ) : (
                    <AskOTP
                      generateOtpFunc={() => generateOtp()}
                      variant="link"
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        {!canUseOtpInput && (
          <Box>
            <Typography variant="body2" sx={{ color: "cobalt.submarine" }}>
              {t("consentLogin:modal.phone.step1.infos2")}
            </Typography>
          </Box>
        )}
        {canUseOtpInput && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              fullWidth={!!isMobile}
              type="submit"
              disabled={
                isFetching || otpError || !formik.isValid || !formik.dirty
              }
              onClick={() => {}}
            >
              Suivant
            </Button>
          </Grid>
        )}
      </form>
    </Box>
  );
};

OtpModal.propTypes = {
  user: PropTypes.shape({
    birthPlace: PropTypes.string.isRequired,
    legalRepresentative: PropTypes.shape({
      mobileNumber: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    }),
    localIpp: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    healthFacilityId: PropTypes.string.isRequired,
    federatedIpp: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    civility: PropTypes.string.isRequired
  }).isRequired
};

export default OtpModal;
