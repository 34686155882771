import React from "react";
import { Typography, Box } from "@mui/material";
import SnackbarUtil from "../../utils/SnackbarUtil";

const SnackbarErrorBlock = () => {
  const [snackbar, setSnackbar] = SnackbarUtil.useSnackbar();
  const { additionnalComponentMethod } = snackbar;

  const closeSnackbar = () => {
    setSnackbar({
      isOpen: false,
      message: "",
      type: undefined,
      additionalComponent: false
    });
  };

  return (
    <Box
      onClick={() => {
        additionnalComponentMethod();
        closeSnackbar();
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: "cobalt.bleuDigital100",
          textDecoration: "underline",
          cursor: "pointer"
        }}
      >
        Réessayer
      </Typography>
    </Box>
  );
};

export default SnackbarErrorBlock;
