import React from "react";
import { SvgIcon } from "@mui/material";

export default function questionIcon(props) {
  return (
    <SvgIcon
      width="40"
      height="60"
      viewBox="0 0 40 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.52926 31.5271C5.79631 31.8931 3.97353 33.9062 3.82031 37.1585C3.82031 41.2787 7.19641 44.6199 11.3597 44.6199V46.9153H20.8382V44.6199M20.8435 44.6199L20.8382 36.714C20.8435 36.6826 20.8435 36.6513 20.8435 36.6147V34.1676C20.8435 33.0591 19.9347 32.1598 18.8146 32.1598H17.4832"
        stroke="#417CFF"
        strokeWidth="0.959785"
        strokeMiterlimit="10"
      />
      <path
        d="M7.27539 37.1583V21.4616C7.27539 20.5622 8.00979 19.8354 8.91853 19.8354C9.82728 19.8354 10.5617 20.5622 10.5617 21.4616V33.1531"
        stroke="#417CFF"
        strokeWidth="0.959785"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.7715 28.4941H12.1029C13.223 28.4941 14.1317 29.3935 14.1317 30.502V32.9961"
        stroke="#417CFF"
        strokeWidth="0.959785"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.1211 29.9114H15.4525C16.5726 29.9114 17.4813 30.8107 17.4813 31.9192V34.4133"
        stroke="#417CFF"
        strokeWidth="0.959785"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9681 56.4671L22.9681 48.2581C22.9681 47.8699 22.6532 47.555 22.2641 47.555L8.87789 47.555C8.48875 47.555 8.17383 47.8699 8.17383 48.2581L8.17383 53.9658C8.17383 55.7359 9.60903 57.1711 11.3782 57.1711L22.2641 57.1711C22.6532 57.1711 22.9681 56.8562 22.9681 56.4671Z"
        fill="#DCE1FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9681 55.8272L22.9681 47.6182C22.9681 47.23 22.6532 46.9151 22.2641 46.9151L8.87789 46.9151C8.48875 46.9151 8.17383 47.23 8.17383 47.6182L8.17383 53.3259C8.17383 55.096 9.60903 56.5312 11.3782 56.5312L22.2641 56.5312C22.6532 56.5312 22.9681 56.2163 22.9681 55.8272Z"
        stroke="#417CFF"
        strokeWidth="0.959785"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5884 50.0338C13.4209 50.0338 14.0959 50.7078 14.0959 51.5402C14.0959 52.3727 13.4209 53.0466 12.5884 53.0466C11.757 53.0466 11.082 52.3727 11.082 51.5402C11.082 50.7078 11.757 50.0338 12.5884 50.0338Z"
        fill="#E5EAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5884 50.0338C13.4209 50.0338 14.0959 50.7078 14.0959 51.5402C14.0959 52.3727 13.4209 53.0466 12.5884 53.0466C11.757 53.0466 11.082 52.3727 11.082 51.5402C11.082 50.7078 11.757 50.0338 12.5884 50.0338Z"
        stroke="#417CFF"
        strokeWidth="0.959785"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4949 24.1946L27.3754 24.1167H27.2327H22.0234C16.833 24.1167 12.6361 19.9654 12.6361 14.8577C12.6361 9.75007 16.833 5.59879 22.0234 5.59879H27.5737C32.7641 5.59879 36.961 9.75007 36.961 14.8577C36.961 18.2812 35.0771 21.2739 32.2694 22.877L32.0274 23.0151V23.2937V26.9042C32.0274 26.9968 31.9131 27.0755 31.8128 27.0101L27.4949 24.1946Z"
        fill="#DCE1FB"
        stroke="#417CFF"
        strokeWidth="0.959785"
      />
      <path
        d="M23.9748 16.3639C23.9748 15.8473 24.0395 15.4356 24.1688 15.1288C24.2982 14.822 24.553 14.486 24.9332 14.1209C25.3174 13.7519 25.5605 13.4898 25.6624 13.3344C25.8192 13.0975 25.8976 12.8411 25.8976 12.5654C25.8976 12.2003 25.8055 11.9226 25.6212 11.7323C25.4409 11.5381 25.1743 11.441 24.8215 11.441C24.4844 11.441 24.2119 11.5361 24.0042 11.7264C23.8003 11.9129 23.6984 12.1673 23.6984 12.4896H22.2695C22.2774 11.8022 22.5126 11.2584 22.9751 10.8584C23.4416 10.4583 24.0571 10.2583 24.8215 10.2583C25.6095 10.2583 26.223 10.4564 26.662 10.8525C27.105 11.2487 27.3265 11.8022 27.3265 12.5129C27.3265 13.146 27.0285 13.7694 26.4327 14.3831L25.7094 15.088C25.4507 15.3793 25.3174 15.8046 25.3096 16.3639H23.9748ZM23.8748 18.1758C23.8748 17.9466 23.9473 17.7621 24.0924 17.6223C24.2374 17.4786 24.4334 17.4068 24.6804 17.4068C24.9313 17.4068 25.1292 17.4805 25.2743 17.6281C25.4193 17.7718 25.4919 17.9544 25.4919 18.1758C25.4919 18.3894 25.4213 18.5681 25.2802 18.7118C25.139 18.8555 24.9391 18.9273 24.6804 18.9273C24.4217 18.9273 24.2217 18.8555 24.0806 18.7118C23.9434 18.5681 23.8748 18.3894 23.8748 18.1758Z"
        fill="#417CFF"
      />
    </SvgIcon>
  );
}
