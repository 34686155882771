import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import AuthService from "../../services/AuthService";
import ConsentService from "../../services/ConsentService";
import ClinicService from "../../services/ClinicService";
import LogoLayout from "../../components/LogoLayout";
import SnackbarUtil from "../../utils/SnackbarUtil";
import ConsentsUtil from "../../utils/ConsentsUtil";
import ConsentContent from "../../components/consent/ConsentContent";
import PatientConsentLoading from "../../components/consent/PatientConsentLoading";
import ConsentNote from "../../components/consent/ConsentNote";
import ConsentDisplayDistribution from "../../components/consent/ConsentDisplayDistribution";

function Consents() {
  const { t } = useTranslation();
  const user = AuthService.getUserData();
  const { localIpp, healthFacilityId } = user;
  const [isLoading, setIsLoading] = useState(true);
  const [consentContent, setConsentContent] = useState([]);
  const [userConsents, setUserConsents] = useState([]);
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicGtu, setClinicGtu] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);

  const setSnackbar = SnackbarUtil.useSnackbar()[1];

  useEffect(() => {
    ClinicService.getClinicDetail(healthFacilityId).then(cResponse => {
      const { value } = cResponse.data[0];
      const clinicDetails = JSON.parse(value);

      setClinicLegalNotice(clinicDetails.legalNotice);
      setClinicGtu(clinicDetails.gtu);
    });

    ClinicService.getClinicImages(healthFacilityId).then(ciResponse => {
      const logoValue = ciResponse.data[0].value;
      const picturesObject = JSON.parse(logoValue);

      if (
        Object.prototype.hasOwnProperty.call(picturesObject, "logo") &&
        picturesObject.logo !== "" &&
        picturesObject.logo !== null
      ) {
        const { logo } = picturesObject;
        setClinicLogo(logo);
      }

      ClinicService.getClinicGDPRInformationNote(healthFacilityId)
        .then(cinResponse => {
          const { data } = cinResponse;
          const rawGDPRInfos = data.filter(note => note.k2 === "GDPR");
          const GDPRInfo = rawGDPRInfos.flat([2]);
          const GDPRInfoNote = JSON.parse(GDPRInfo[0].value);
          setConsentContent(GDPRInfoNote.description);
        })
        .catch(error => console.log(error));

      ConsentService.getAllGdprConsents(localIpp, healthFacilityId)
        .then(myRes => {
          const { data } = myRes;

          setIsLoading(false);
          setUserConsents(ConsentsUtil.orderConsents(data));
        })
        .catch(() => {
          setSnackbar({
            isOpen: true,
            message: "Une erreur est survenue. Merci de réessayer.",
            type: "error",
          });
        });
    });
  }, [localIpp, healthFacilityId, setSnackbar]);

  return (
    <LogoLayout
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
      containerSize="lg"
    >
      <Box mb={1}>
        <Typography variant="h1">{`${user.firstName} ${user.lastName}`}</Typography>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" sx={{ color: "cobalt.lynch" }}>
          {format(new Date(user.birthDate), "dd.MM.yyyy")}
        </Typography>
      </Box>
      {consentContent === null ||
      (consentContent.length === 1 && consentContent[0] === "") ? (
        <Box />
      ) : (
        <Box>
          <ConsentContent
            isLoading={isLoading}
            content={consentContent}
            title={{ titleLabel: t("newConsent:infoNote") }}
          />
        </Box>
      )}
      <Box mt={4} mb={2} ml={5}>
        <Typography variant="h3">{t("newConsent:consents")}</Typography>
      </Box>
      {isLoading ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <PatientConsentLoading />
          <PatientConsentLoading />
          <PatientConsentLoading />
          <PatientConsentLoading />
        </Grid>
      ) : (
        <ConsentDisplayDistribution consents={userConsents} />
      )}
      <Box mt={5} mb={2} ml={5}>
        <Typography variant="h3">{t("newConsent:toNote")}</Typography>
      </Box>
      <Box mb={5}>
        <ConsentNote />
      </Box>
    </LogoLayout>
  );
}

export default Consents;
