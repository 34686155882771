import React from "react";
import { red, orange, green } from "@mui/material/colors";
import { CobaltIcon } from "da-design-system";
import theme from "../theme";

const TableUtils = {
  getConsentsColumns: () => [
    {
      type: "intentStartDate",
      label: "Date",
      cellType: "TEXT",
      align: "left",
      active: true,
      direction: "asc"
    },
    {
      type: "intentDescription",
      label: "Type",
      cellType: "TEXT",
      align: "left",
      active: false,
      direction: "asc"
    },
    // {
    //   type: "clinic",
    //   label: "Etablissement responsable",
    //   cellType: "TEXT",
    //   align: "left",
    //   active: false,
    //   direction: "asc"
    // },
    {
      type: "status",
      label: "Statut",
      cellType: "STATUS",
      align: "center",
      active: false,
      direction: "asc"
    }
  ],
  changeColumnsDirection: (sort, column) => {
    if (sort.column === column.type) {
      return {
        type: "direction",
        value: sort.direction === "desc" ? "asc" : "desc"
      };
    }

    return { type: "column", value: column.type };
  },
  cellStatusElements: () => [
    {
      type: "COMPLETED",
      colors: {
        bgcolor: green[50],
        color: green[900]
      },
      icon: (
        <CobaltIcon
          name="check"
          style={{ color: theme.palette.cobalt.greenIcon, fontSize: 18 }}
        />
      )
    },
    {
      type: "REQUESTED",
      colors: {
        bgcolor: orange[50],
        color: orange[900]
      },
      icon: (
        <CobaltIcon
          name="eye"
          style={{
            color: theme.palette.cobalt.orangeIcon,
            fontSize: 18
          }}
        />
      )
    },
    {
      type: "REFUSED",
      colors: {
        bgcolor: red[50],
        color: red[900]
      },
      icon: (
        <CobaltIcon
          name="x"
          style={{
            color: theme.palette.cobalt.redIcon,
            fontSize: 18
          }}
        />
      )
    }
  ]
};

export default TableUtils;
