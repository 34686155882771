import hubService from "./HubService";
import authService from "./AuthService";

//sendEmailConfirmation => Modification POC

const ConsentService = {
  getPatientByBirthdate: (birthDate, localIpp, healthFacilityId) => {
    const dateElements = birthDate.split("-");

    const year = dateElements[0];
    const month = dateElements[1];
    const day = dateElements[2];

    const url = `patients?birthDate=${year}-${month}-${day}&localIpp=${localIpp}&healthFacilityId=${healthFacilityId}`;

    return hubService.request("GET", url);
  },
  generateOtp: (
    localIpp,
    healthFacilityId,
    contextId = null,
    globalIntentType = ""
  ) => {
    const url = "otpNotification";
    const body =
      globalIntentType === "MEDICAL"
        ? { localIpp, healthFacilityId, contextId }
        : { localIpp, healthFacilityId };

    return hubService.request("POST", url, body);
  },
  validateOtp: (codeOtp, localIpp, healthFacilityId) => {
    const url = "otpValidation";
    const body = {
      healthFacilityId,
      otpCode: parseInt(codeOtp, 10),
      localIpp,
    };

    return hubService.request("POST", url, body);
  },
  getPatientConsents: (localIpp, healthFacilityId) => {
    const url = `consents?localIpp=${localIpp}&healthFacilityId=${healthFacilityId}`;

    return hubService.request("GET", url);
  },
  updatePatientConsents: (consentId, body) => {
    const url = `consentValidation/${consentId}`;

    return hubService.request("POST", url, body);
  },
  getAllRequestedConsents: (localIpp, healthFacilityId) => {
    const url = `consents?localIpp=${localIpp}&healthFacilityId=${healthFacilityId}&gdprStatus=REQUESTED&medicalStatus=REQUESTED`;

    return hubService.request("GET", url);
  },
  getAllPatientConsents: (localIpp, healthFacilityId) => {
    const url = `consents?localIpp=${localIpp}&healthFacilityId=${healthFacilityId}`;

    return hubService.request("GET", url);
  },
  getAllGdprConsents: (localIpp, healthFacilityId) => {
    const url = `consents?localIpp=${localIpp}&healthFacilityId=${healthFacilityId}&intentType=GDPR`;

    return hubService.request("GET", url);
  },
  getAllMedicalConsents: (localIpp, healthFacilityId) => {
    const url = `consents?localIpp=${localIpp}&healthFacilityId=${healthFacilityId}&intentType=MEDICAL`;

    return hubService.request("GET", url);
  },
  getConsentById: (consentId) => {
    const url = `consents/${consentId}`;

    return hubService.request("GET", url);
  },
  getResponsableRecueil: () => {
    const PATIENT = "PATIENT";
    const REPRESENTANT_LEGAL = "LEGAL_REPRESENTATIVE";
    const userData = authService.getUserData();

    if (userData.legalRepresentative !== undefined) {
      const { legalRepresentative } = userData;

      if (
        legalRepresentative.mobilePhone !== "" ||
        legalRepresentative.email !== ""
      ) {
        return REPRESENTANT_LEGAL;
      }
    }

    return PATIENT;
  },
  sendEmailConfirmation: (patientId, intentType, token = null) => {
    const url = `consentNotifications`;
    const body =
      intentType === "MEDICAL"
        ? {
            patientId,
            intentType,
            isCreationNotification: false,
            contextId: token,
          }
        : { patientId, intentType };

    return hubService.request("POST", url, body);
  },
  getConsentValidation: (consentId, body) => {
    const url = `consentValidation/${consentId}`;
    return hubService.request("POST", url, body);
  },
  downloadSignedConsent: (consentId, localIpp, healthFacilityId) => {
    const url = `signedConsents?consentId=${consentId}&localIpp=${localIpp}&healthFacilityId=${healthFacilityId}`;
    return hubService.request("GET", url, null, false, false, "text/plain");
  },
};

export default ConsentService;
