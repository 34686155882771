import React, { useState } from "react";
import PropTypes from "prop-types";
import AuthContext from "./authContext";

const AuthProvider = ({ children }) => {
  const [currentToken, setCurrentToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        currentToken,
        isAuthenticated,
        setCurrentToken,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
