import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import { CobaltIcon } from "da-design-system";

const ConsentFrequentAsk = props => {
  const { drawerControl } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState("");

  const handleChangeOfQuestion = panel => (_e, target) => {
    setIsExpanded(target ? panel : false);
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          borderBottom: "1px solid #ececef",
          py: 2,
        }}
      >
        <Typography variant="h4" sx={{ ml: 4 }}>
          Questions fréquentes
        </Typography>
        <IconButton onClick={drawerControl} sx={{ mr: 4 }}>
          <CobaltIcon
            name="x"
            style={{
              fontSize: "20px",
              color: theme.palette.cobalt.ultramarine,
            }}
          />
        </IconButton>
      </Grid>

      <Grid item px={3} sx={{ maxHeight: "85vh", overflow: "scroll" }}>
        {Object.values(
          t("frequentlyAsked:questions", {
            returnObjects: true,
          }),
        ).map(({ id, question, answer }) => (
          <Accordion
            key={`question-${id}`}
            sx={{
              boxShadow: "none",
            }}
            expanded={isExpanded === `question-${id}`}
            onChange={handleChangeOfQuestion(`question-${id}`)}
            disableGutters
          >
            <AccordionSummary
              expandIcon={
                <CobaltIcon
                  name="chevron-up"
                  style={{
                    color: theme.palette.cobalt.ultramarine,
                    fontSize: "24px",
                  }}
                />
              }
              sx={{
                p: 2,
              }}
            >
              <Typography variant="subtitle2">{question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{ color: "cobalt.lynch", whiteSpace: "pre-line" }}
              >
                {answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

ConsentFrequentAsk.propTypes = {
  drawerControl: PropTypes.func.isRequired,
};
export default ConsentFrequentAsk;
