import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { withTranslation, useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  Grid,
  Paper,
  Box,
  useMediaQuery,
  Typography,
  Button
} from "@mui/material";
import { CobaltIcon } from "da-design-system";

const CAIHIntervention = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // eslint-disable-next-line react/destructuring-assignment
  const actualConsent = props.consent;
  const { hasMultipleInterventions, legalRepresentative } = props;
  const history = useHistory();

  const consent = {
    ...actualConsent,
    hasMultipleInterventions,
    legalRepresentative
  };
  const { interventionDate, interventionId, consentsList } = consent;

  return (
    <Grid item xs={12}>
      <Paper sx={{ borderRadius: "8px" }}>
        <Box
          sx={{
            padding: "16px 24px",
            marginBottom: isMobile ? "0px" : "12px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.cobalt.grey10,
            borderBottomWidth: !isMobile ? "1px" : 0
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="nowrap"
          >
            <CobaltIcon
              name="calendar"
              style={{
                marginRight: isMobile ? "8px" : "12px",
                color: theme.palette.cobalt.ultramarine
              }}
            />
            <Typography variant={isMobile ? "h5" : "h4"}>
              {`Intervention du ${format(
                new Date(interventionDate),
                "dd/MM/yyyy à HH:mm"
              )}`}
            </Typography>
          </Grid>
        </Box>

        {consentsList.map((consentDetail, i) => (
          <Box
            sx={{
              padding: "6px 24px"
            }}
            key={`${interventionId}-${i.toString()}`}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              flexWrap="nowrap"
            >
              <CobaltIcon
                name="bullet-point"
                style={{
                  marginRight: isMobile ? "8px" : "12px",
                  width: isMobile ? "18px" : "20px",
                  height: isMobile ? "18px" : "20px"
                }}
              />
              <Box
                sx={{
                  fontFamily: "'Barlow', sans-serif",
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: 500,
                  letterSpacing: "normal",
                  lineHeight: 1.2,
                  color: "cobalt.ultramarine"
                }}
              >
                {consentDetail.title}
              </Box>
            </Grid>
          </Box>
        ))}
        {isMobile ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              padding: "8px",
              borderTopStyle: "solid",
              borderTopWidth: "1px",
              borderTopColor: theme.palette.cobalt.grey10,
              marginTop: "8px"
            }}
          >
            <Button
              size="small"
              variant="text"
              // id={`button-request-${consent.interventionId}`}
              endIcon={
                <CobaltIcon
                  name="arrow-right"
                  style={{ color: theme.palette.cobalt.bleuDigital100 }}
                />
              }
              onClick={() =>
                history.push("/consent/intervention/sign", consent)
              }
            >
              {t("intervention:continue")}
            </Button>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ padding: "0px 24px 16px 24px" }}
          >
            <Button
              // id={`button-request-${consent.interventionId}`}
              size="small"
              endIcon={
                <CobaltIcon
                  name="arrow-right"
                  style={{ color: theme.palette.cobalt.white }}
                />
              }
              onClick={() =>
                history.push("/consent/intervention/sign", consent)
              }
            >
              {t("intervention:continue")}
            </Button>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

CAIHIntervention.propTypes = {
  consent: PropTypes.shape({
    interventionDate: PropTypes.string.isRequired,
    interventionId: PropTypes.string.isRequired,
    consentsList: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  legalRepresentative: PropTypes.string.isRequired,
  hasMultipleInterventions: PropTypes.bool.isRequired
};

export default withTranslation()(CAIHIntervention);
