import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import ClinicService from "../../services/ClinicService";

const useClinicInformations = (healthFacilityId) => {
  //const [clinics, setClinics] = useState([]);
  const [clinicLogo, setClinicLogo] = useState(null);
  const [clinicGHTLogo, setClinicGHTLogo] = useState(null);
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicName, setClinicName] = useState(false);
  const [clinicGtu, setClinicGtu] = useState("");

  // useQuery({
  //   queryKey: ["getAllEts"],
  //   retry: 0,
  //   queryFn: () => ClinicService.getClinics(),

  //   onSuccess: (res) => {
  //     const formattedClinics = res.data
  //       .map((d) => ({ ...JSON.parse(d.value), healthFacilityId: d.k1 }))
  //       .filter((d) => !d.hidden)
  //       .sort((a, b) => a.shortName.localeCompare(b.shortName));

  //     setClinics(formattedClinics);
  //   },
  //   onError: () => setClinics([]),
  //   enabled: clinics.length === 0,
  // });

  //TODO use Queries
  useQuery({
    queryKey: ["getClinicDetails"],
    retry: 0,
    queryFn: () => ClinicService.getClinicDetail(healthFacilityId),

    onSuccess: (res) => {
      const { value } = res.data[0];
      const clinicDetails = JSON.parse(value);
      setClinicLegalNotice(clinicDetails.legalNotice);
      setClinicGtu(clinicDetails.gtu);
      setClinicName(clinicDetails.shortName);
    },
    onError: () => {
      setClinicLegalNotice("");
      setClinicGtu("");
      setClinicName("");
    },
    enabled: !!healthFacilityId,
  });

  useQuery({
    queryKey: ["getClinicLogo"],
    retry: 0,
    queryFn: () => ClinicService.getClinicImages(healthFacilityId),
    onSuccess: (res) => {
      const logoValue = res.data[0].value;
      const picturesObject = JSON.parse(logoValue);
      const { logo, logoGHT } = picturesObject;

      setClinicLogo(logo);
      setClinicGHTLogo(logoGHT);
    },
    onError: () => {
      setClinicLogo("");
      setClinicGHTLogo("");
    },
    enabled: !!healthFacilityId,
  });

  // Information Note
  //        ClinicService.getClinicGDPRInformationNote(idClinic)
  //       .then((cinResponse) => {
  //         const { data } = cinResponse;
  //         const GDPRInfoNote = JSON.parse(data[0].value);
  //         setNewContent(GDPRInfoNote.description);
  //       })
  //       .catch(() => setNewContent([]));

  //     getAllPatientConsents(ipp, idClinic);
  //   }, [state, getAllPatientConsents]);

  return {
    clinicGHTLogo,
    clinicLogo,
    clinicLegalNotice,
    clinicName,
    clinicGtu,
  };
};

useClinicInformations.propTypes = {
  healthFacilityId: PropTypes.string.isRequired,
};

export default useClinicInformations;
