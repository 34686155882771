import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CobaltIcon } from "da-design-system";

const InterventionNote = props => {
  const { currentStep } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const stepOneIsDone = currentStep === 2;

  const styles = {
    block: {
      width: isMobile ? 26 : 32,
      height: isMobile ? 26 : 32,
      borderRadius: isMobile ? "13px" : "16px",
      color: "cobalt.white",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: isMobile ? "26px" : "32px",
      textAlign: "center",
      fontFamily: "'Montserrat', sans-serif"
    },
    isBlockActive: {
      backgroundColor: "cobalt.bleuDigital100"
    },
    isTextActive: {
      color: "cobalt.bleuDigital100",
      px: 1
    },
    isBlockDisabled: {
      backgroundColor: "cobalt.grey20"
    },
    isTextDisabled: {
      color: "cobalt.grey20",
      px: 1
    }
  };

  return (
    <>
      {stepOneIsDone ? (
        <CobaltIcon
          name="check-circle"
          style={{
            color: theme.palette.cobalt.bleuDigital100,
            fontSize: isMobile ? 26 : 32
          }}
        />
      ) : (
        <Box sx={{ ...styles.block, ...styles.isBlockActive }}>1</Box>
      )}

      <Typography
        variant={isMobile ? "subtitle2" : "subtitle1"}
        sx={{
          ...styles.isTextActive,
          mr: 3
        }}
      >
        {t("intervention:sign.readAndSign")}
      </Typography>

      <Box
        sx={
          stepOneIsDone
            ? {
                ...styles.block,
                ...styles.isBlockActive
              }
            : { ...styles.block, ...styles.isBlockDisabled }
        }
      >
        2
      </Box>

      <Typography
        variant={isMobile ? "subtitle2" : "subtitle1"}
        sx={
          stepOneIsDone
            ? {
                ...styles.isTextActive
              }
            : { ...styles.isTextDisabled }
        }
      >
        {t("intervention:sign.download")}
      </Typography>
    </>
  );
};

InterventionNote.propTypes = {
  currentStep: PropTypes.number.isRequired
};

export default InterventionNote;
