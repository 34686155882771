import React from "react";
import PropTypes from "prop-types";
import { Box, Card, CardHeader, CardContent } from "@mui/material";

const CAIHCard = props => {
  const {
    title,
    children,
    hasGutter,
    hasPadding,
    cardContentNoPadding,
    hideOnMobile
  } = props;

  return (
    <Box mb={hasGutter ? 2 : 1}>
      <Card
        sx={{
          borderRadius: "8px",
          padding: hasPadding ? "26px" : "0px",
          boxShadow: hideOnMobile
            ? "none"
            : "0px 4px 8px rgba(102, 109, 146, 0.08)",
          backgroundColor: hideOnMobile && "transparent"
        }}
        width="100%"
      >
        {title !== null && <CardHeader title={title} />}
        <CardContent style={{ padding: cardContentNoPadding ? "0px" : "16px" }}>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

CAIHCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
  hasGutter: PropTypes.bool,
  hasPadding: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  cardContentNoPadding: PropTypes.bool
};

CAIHCard.defaultProps = {
  title: null,
  hasGutter: false,
  hasPadding: true,
  cardContentNoPadding: false,
  hideOnMobile: false
};

export default CAIHCard;
