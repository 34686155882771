import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "@mui/material";

const CAIHDrawer = (props) => {
  const { isOpen, anchor, children, isFAQ } = props;

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      sx={{ backgroundColor: "rgba(102, 109, 146, 0.3)" }}
      PaperProps={{
        sx: {
          width: isFAQ ? "500px" : "100vw",
          padding: isFAQ ? "88px 0px 0px 0px" : "88px 24px 24px 24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: isFAQ ? "center" : "flex-start",
          overflow: "hidden",
        },
      }}
    >
      {children}
    </Drawer>
  );
};

CAIHDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isFAQ: PropTypes.bool.isRequired,
};

export default CAIHDrawer;
