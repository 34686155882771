/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Paper,
  useMediaQuery,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import { CobaltIcon } from "da-design-system";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Iframe from "react-iframe";
import LogoLayout from "../../components/LogoLayout";
import QuestionTooltipIcon from "../../icons/QuestionTooltipIcon";
import CAIHLoader from "../../components/CAIHLoader";
import PatientService from "../../services/PatientService";
import ConsentService from "../../services/ConsentService";
import SnackbarUtil from "../../utils/SnackbarUtil";
import InterventionStep from "../../components/intervention/InterventionStep";
import AuthContext from "../../context/authContext";

//! A remettre en place : le style des tooltip

const InterventionListing = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentToken } = useContext(AuthContext);
  const { location } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let { state } = location;
  const [contraliaPage, setContraliaPage] = useState(null);
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicGtu, setClinicGtu] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);
  const [open, setOpen] = useState(false);
  const setSnackbar = SnackbarUtil.useSnackbar()[1];

  const [validatedConsents, setValidatedConsents] = useState([]);
  const [rejectedConsents, setRejectedConsents] = useState([]);

  if (state === undefined) {
    state = {
      healthFacilityId: undefined,
      ipp: undefined,
      clinicLogo: undefined,
      clinicGtu: undefined,
      clinicLegalNotice: undefined,
      patientId: undefined,
      legalRepresentative: undefined,
      interventionId: undefined,
      interventionDate: undefined,
      consentsList: [],
      hasMultipleInterventions: undefined,
    };
    history.push("/");
  }

  // Affiche la frame Contralia correspondant à l'intervention sélectionnée
  const { refetch: getSignature } = useQuery({
    queryKey: ["getContraliaSignature"],
    retry: 0,
    queryFn: () =>
      PatientService.getSignature(
        state.consentsList.map((c) => ({
          consentId: c.consentId,
        })),
        currentToken
      ),
    onSettled: () => {
      setValidatedConsents([]);
      setRejectedConsents([]);
    },
    onSuccess: ({ data }) => {
      // Variation possible dans la réception de l'url
      const signature = data.signatureUrl?.url ?? data.signatureUrl;

      // Quand signatureUrl est renvoyé null dans les deux cas
      if (!signature) {
        setSnackbar({
          isOpen: true,
          message: "Erreur lors de la génération du document",
          type: "error",
          additionalComponent: true,
          additionnalComponentMethod: getSignature,
        });
      } else {
        setContraliaPage(signature);
      }
    },
    onError: () => {
      setSnackbar({
        isOpen: true,
        message: "Erreur lors de la génération du document",
        type: "error",
        additionalComponent: true,
        additionnalComponentMethod: getSignature,
      });
    },
    enabled: false,
  });

  useEffect(() => getSignature(), []);

  // Validation de la signature des consentements de l'intervention
  const { mutate: validateConsent } = useMutation({
    mutationKey: "validateConsentSignature",
    mutationFn: ({ consentId }) =>
      ConsentService.getConsentValidation(consentId, {
        status: "COMPLETED",
        consentCollectorCategoryCode:
          state.legalRepresentative === "yes"
            ? "LEGAL_REPRESENTATIVE"
            : "PATIENT",
      }),
    onSuccess: ({ data }) => {
      const { contraliaTransactionId, id, medicalConsentTemplateTitle } = data;
      const newConsent = {
        contraliaTransactionId,
        id,
        medicalConsentTemplateTitle,
      };
      setValidatedConsents([...validatedConsents, newConsent]);
    },
    onError: () => {
      const unvalidatedConsents = state.consentsList.filter(
        (consent) =>
          !validatedConsents.some(
            (validateConsent) => validateConsent.id === consent.consentId
          )
      );

      setRejectedConsents(unvalidatedConsents);

      setSnackbar({
        isOpen: true,
        message:
          "Une erreur est survenue lors de la validation des consentements",
        type: "error",
        additionalComponent: true,
        additionnalComponentMethod: () =>
          unvalidatedConsents.map((consent) =>
            validateConsent({ consentId: consent.consentId })
          ),
      });
    },
    enabled: false,
  });

  // Envoi du mail de confirmation
  useQuery({
    queryKey: ["sendConfirmation"],
    retry: 0,
    queryFn: () =>
      ConsentService.sendEmailConfirmation(
        state.patientId,
        "MEDICAL",
        currentToken
      ),
    onSuccess: () => {
      const consentParams = {
        healthFacilityId: state.healthFacilityId,
        ipp: state.ipp,
        name: state.name,
        birthdate: state.birthdate,
        clinicGtu: state.clinicGtu,
        clinicLogo: state.clinicLogo,
        clinicLegalNotice: state.clinicLegalNotice,
        hasMultipleInterventions: state.hasMultipleInterventions,
        legalRepresentative: state.legalRepresentative,
        validatedConsents,
        rejectedConsents,
      };
      if (validatedConsents.length === state.consentsList.length) {
        history.push("/consent/intervention/signed", consentParams);
      } else {
        // Si les consentements ont été partiellement validés
        history.push("/consent/intervention/signed", consentParams);
        setSnackbar({
          isOpen: true,
          message: "Au moins un consentement n'a pas pu être validé",
          type: "error",
        });
      }
    },
    onError: () => {
      setSnackbar({
        isOpen: true,
        message: t("layout:alert.mail"),
        type: "error",
      });
    },
    enabled:
      Number(validatedConsents.length) + Number(rejectedConsents.length) ===
        state.consentsList.length && validatedConsents.length > 0,
  });

  useEffect(() => {
    setClinicLegalNotice(state.clinicLegalNotice);
    setClinicGtu(state.clinicGtu);
    setClinicLogo(state.clinicLogo);
  }, [state]);

  useEffect(() => {
    const isPropertyValid = (item, property) => {
      if (
        Object.prototype.hasOwnProperty.call(item, property) &&
        item[property] !== "" &&
        item[property] !== null
      ) {
        return true;
      }
      return false;
    };

    const iFrameHandler = (event) => {
      const signatureParams = event.data.params;
      const { consentsList } = state;

      // Si les propriétés mode et userAction existent et ont les valeurs souhaitées, on lance la validation des consentements
      if (
        signatureParams !== undefined &&
        isPropertyValid(signatureParams, "mode") &&
        isPropertyValid(signatureParams, "userAction")
      ) {
        if (
          signatureParams.mode === "SIGN" &&
          signatureParams.userAction === "OK"
        ) {
          return consentsList.map((consent) =>
            validateConsent({
              consentId: consent.consentId,
            })
          );
        }
      }
    };
    window.addEventListener("message", iFrameHandler);
  }, []);

  const getDate = (date) =>
    format(new Date(date), "dd MMMM yyyy", { locale: fr });

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <LogoLayout
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
      headerStepper={<InterventionStep currentStep={1} />}
      containerSize="xl"
    >
      {!isMobile ? (
        <Box>
          <Button
            variant="text"
            startIcon={
              <CobaltIcon
                name="arrow-left"
                style={{
                  color: theme.palette.cobalt.bleuDigital100,
                }}
              />
            }
            onClick={() => {
              setSnackbar({
                isOpen: false,
              });
              history.push("/consent/intervention", {
                idClinic: state.healthFacilityId,
                ipp: state.ipp,
                hasBothConsentType: false,
                name: state.name,
                birthdate: state.birthdate,
                clinicLogo: state.clinicLogo,
                clinicGtu: state.clinicGtu,
                clinicLegalNotice: state.clinicLegalNotice,
                legalRepresentative: state.legalRepresentative,
              });
            }}
          >
            {t("Retour")}
          </Button>
          <Box mt={1}>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  borderRadius: "8px",
                  boxShadow:
                    "0 0 1px 0 rgba(0, 0, 45, 0.04), 0 0 2px 0 rgba(0, 0, 45, 0.06), 0 4px 8px 0 rgba(0, 0, 46, 0.04)",
                }}
              >
                <Box
                  sx={{
                    padding: "32px",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      marginBottom: 3,
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          color: "cobalt.lynch",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: 1.15,
                          letterSpacing: "0.41px",
                          marginBottom: "4px",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <CobaltIcon
                            name="calendar"
                            style={{
                              color: theme.palette.cobalt.lynch,
                            }}
                          />
                          {state.interventionDate !== undefined && (
                            <Box ml={1}>
                              {`Intervention du ${getDate(
                                state.interventionDate
                              )}`}
                            </Box>
                          )}
                        </Grid>
                      </Box>
                      <Box>
                        <Typography variant="h3">
                          {`${state.consentsList.length} consentement${
                            state.consentsList.length > 1 ? "s" : ""
                          } à signer`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box mr={1}>
                      <Tooltip
                        title={t("intervention:sign.how")}
                        placement="top"
                        arrow
                      >
                        <Box
                          sx={{
                            padding: "6px 8px",
                            borderRadius: "42px",
                            backgroundColor: "cobalt.bleuDigital05",
                            color: "cobalt.ultramarine",
                            fontFamily: "'Montserrat', sans-serif",
                            fontSize: "14px",
                            lineHeight: 1.15,
                            letterSpacing: "0.41px",
                            fontWeight: 600,
                            cursor: "pointer",
                            ":hover": {
                              color: "#3970e6",
                              "& div > svg": {
                                color: "#3970e6",
                              },
                            },
                            "& div > svg": {
                              color: "cobalt.ultramarine",
                            },
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <CobaltIcon name="help-circle" />
                            <Box ml={1}>Comment signer ?</Box>
                          </Grid>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                  {contraliaPage === null ? (
                    <Grid
                      container
                      justifyContent="center"
                      sx={{
                        color: "cobalt.bleuDigital100",
                        height: "564px",
                      }}
                    >
                      <CAIHLoader
                        label={t("intervention:iframeActions:loading")}
                        size="20px"
                        color="cobalt.bleuDigital100"
                      />
                    </Grid>
                  ) : (
                    <Iframe
                      src={contraliaPage}
                      onLoad={window.top.postMessage({
                        message: "init",
                      })}
                      width="100%"
                      height="564px"
                      position="relative"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                      }}
                    />
                  )}
                </Box>
              </Paper>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                }}
                onClick={() =>
                  history.push("/consent/intervention", {
                    idClinic: state.healthFacilityId,
                    ipp: state.ipp,
                    hasBothConsentType: false,
                    name: state.name,
                    birthdate: state.birthdate,
                    clinicLogo: state.clinicLogo,
                    clinicGtu: state.clinicGtu,
                    clinicLegalNotice: state.clinicLegalNotice,
                  })
                }
                mr={1}
              >
                <CobaltIcon
                  name="arrow-left"
                  style={{
                    color: theme.palette.cobalt.bleuDigital100,
                  }}
                />
              </Box>
              <Box
                onClick={() =>
                  history.push("/consent/intervention", {
                    idClinic: state.healthFacilityId,
                    ipp: state.ipp,
                    hasBothConsentType: false,
                    name: state.name,
                    birthdate: state.birthdate,
                    clinicLogo: state.clinicLogo,
                    clinicGtu: state.clinicGtu,
                    clinicLegalNotice: state.clinicLegalNotice,
                  })
                }
                sx={{
                  fontFamily: "'Barlow', sans-serif",
                  fontSize: 14,
                  lineHeight: 1.2,
                  color: "cobalt.bleuDigital100",
                  fontWeight: 500,
                }}
              >
                Retour
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "12px",
              marginBottom: "18px",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
            >
              <Box>
                <Box
                  sx={{
                    fontFamily: "'Montserrat', sans-serif",
                    color: "cobalt.lynch",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: 1.15,
                    letterSpacing: "0.41px",
                  }}
                  mb={1}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="nowrap"
                  >
                    <CobaltIcon
                      name="calendar"
                      style={{
                        color: theme.palette.cobalt.lynch,
                      }}
                    />
                    {state.interventionDate !== undefined && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "cobalt.lynch",
                          ml: 1,
                        }}
                      >
                        {`Intervention du ${getDate(state.interventionDate)}`}
                      </Typography>
                    )}
                  </Grid>
                </Box>
                <Box>
                  <Typography variant="h3">
                    {`${state.consentsList.length} consentement${
                      state.consentsList.length > 1 ? "s" : ""
                    } à signer`}
                  </Typography>
                </Box>
              </Box>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Box>
                  <Tooltip
                    title={t("intervention:sign.how")}
                    placement="left"
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    arrow
                  >
                    <Box
                      sx={{
                        padding: "8px",
                        borderRadius: "20px",
                        backgroundColor: "cobalt.bleuDigital05",
                      }}
                      onClick={open ? handleTooltipClose : handleTooltipOpen}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                          "& svg": {
                            fill: theme.palette.cobalt.ultramarine,
                          },
                        }}
                      >
                        <QuestionTooltipIcon
                          style={{
                            fill: "transparent",
                          }}
                        />
                      </Grid>
                    </Box>
                  </Tooltip>
                </Box>
              </ClickAwayListener>
            </Grid>
          </Box>
          <Box
            sx={{
              height: "calc(100vh - 138px)",
            }}
          >
            {contraliaPage === null ? (
              <Grid
                container
                justifyContent="center"
                sx={{
                  color: "cobalt.bleuDigital100",
                  height: "100%",
                }}
              >
                <CAIHLoader
                  label={t("intervention:iframeActions:loading")}
                  size="20px"
                  color="cobalt.bleuDigital100"
                />
              </Grid>
            ) : (
              <Iframe
                src={contraliaPage}
                width="100%"
                height="100%"
                position="relative"
                style={{
                  border: "none",
                  backgroundColor: "white",
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </LogoLayout>
  );
};

InterventionListing.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      healthFacilityId: PropTypes.string.isRequired,
      ipp: PropTypes.string.isRequired,
      clinicLogo: PropTypes.string.isRequired,
      clinicGtu: PropTypes.string.isRequired,
      clinicLegalNotice: PropTypes.string.isRequired,
      birthdate: PropTypes.instanceOf(Date).isRequired,
      name: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      legalRepresentative: PropTypes.string.isRequired,
      interventionId: PropTypes.string.isRequired,
      interventionDate: PropTypes.string.isRequired,
      consentsList: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
        })
      ).isRequired,
      hasMultipleInterventions: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default InterventionListing;
