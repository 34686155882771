import { useMemo } from "react";
import PropTypes from "prop-types";

const useStepValidation = (errors, form, isRPSingle, trigger) => {
  const isFormValid = useMemo(() => {
    if (Object.values(form).length) {
      const {
        legalRepresentative1,
        legalRepresentative2,
        reason,
        complementaryReason,
      } = form;

      if (isRPSingle) {
        // En cas de raison Autre, on valide l'envoi sur la saisie de la raison complémetaire
        if (reason === "Autre") {
          return Object.values(legalRepresentative1)
            .map((lr) => !!lr && !!lr.length)
            .concat(
              !!complementaryReason &&
                !!complementaryReason.length &&
                complementaryReason.length <= 100
            );
        }
        // Sinon, juste sur la raison
        return Object.values(legalRepresentative1)
          .map((lr) => !!lr && !!lr.length)
          .concat(!!reason && !!reason.length);
      }

      return Object.values(legalRepresentative1)
        .map((lr) => !!lr && !!lr.length)
        .concat(
          Object.values(legalRepresentative2).map((lr) => !!lr && !!lr.length)
        );
    }
    return [false];
  }, [isRPSingle, form]);

  const isStepValid = useMemo(() => {
    return (
      isFormValid.length && isFormValid.every((validatedItem) => validatedItem)
    );
  }, [isFormValid]);

  return { isStepValid };
};

useStepValidation.propTypes = {
  errors: PropTypes.arrayOf.isRequired,
  form: PropTypes.arrayOf.isRequired,
  isRPSingle: PropTypes.bool.isRequired,
};

export default useStepValidation;
