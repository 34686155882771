import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CobaltIcon } from "da-design-system";
import { Button, Grid } from "@mui/material";
import CAIHDrawerItem from "./CAIHDrawerItem";
import AuthService from "../services/AuthService";

const Nav = (props) => {
  const { isAuthenticated, handleFAQOpening, legalNotice, gtu } = props;
  const history = useHistory();

  const handleDisconnexion = () => {
    AuthService.logout();
    history.push("/");
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {!!legalNotice.length && (
        <CAIHDrawerItem onClick={() => window.open(legalNotice)}>
          <Button variant="delta">Mentions légales</Button>
        </CAIHDrawerItem>
      )}

      {!!gtu.length && (
        <CAIHDrawerItem onClick={() => window.open(gtu)}>
          <Button variant="delta">CGU</Button>
        </CAIHDrawerItem>
      )}
      <CAIHDrawerItem onClick={handleFAQOpening}>
        <Button
          variant="delta"
          onClick={handleFAQOpening}
          startIcon={<CobaltIcon name="help-circle" />}
        >
          Questions fréquentes
        </Button>
      </CAIHDrawerItem>

      {isAuthenticated && (
        <CAIHDrawerItem onClick={handleDisconnexion}>
          <Button
            variant="delta"
            onClick={handleDisconnexion}
            startIcon={<CobaltIcon name="log-out" />}
          >
            Déconnexion
          </Button>
        </CAIHDrawerItem>
      )}
    </Grid>
  );
};

Nav.propTypes = {
  isAuthenticated: PropTypes.string.isRequired,
  handleFAQOpening: PropTypes.func.isRequired,
  legalNotice: PropTypes.string.isRequired,
  gtu: PropTypes.string.isRequired,
};

export default Nav;
