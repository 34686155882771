const dev = {
  HUB_TOKEN_REQUEST_URL: "http://localhost:3001/api/caih/request",
  HUB_TOKEN_OPERATOR_REQUEST_URL: "http://localhost:3001/api/caih-op/request",
  HUB_SIGNED_REQUEST_URL: "http://localhost:3001/api/caih/signedRequest",
  HUB_KEYCLOAK_REQUEST_URL: "http://localhost:3001/api/caih/keycloakRequest",
  HUB_REQUEST_URL: "https://www.rec2.docapost.io/hub/v2/api/caih/",
  HUB_KEYCLOAK_DOMAIN:
    "https://hub.eco6.tech/auth/realms/CAIH_RECUEIL_ASSISTE/",
  HUB_KEYCLOAK_CLIENTID: "c9aa2cda8e584233b77a922931238adb",
  CAIH_DOMAIN: "http://localhost:3000/",
  KEYCLOAK_JS: "https://hub.eco6.tech/auth/js/keycloak.js",
  KEYCLOAK_CONF: {
    realm: "CAIH_RECUEIL_ASSISTE",
    "auth-server-url": "https://hub.eco6.tech/auth",
    "ssl-required": "external",
    resource: "c9aa2cda8e584233b77a922931238adb",
    clientId: "c9aa2cda8e584233b77a922931238adb",
    "confidential-port": 0
  }
};

const recette = {
  HUB_TOKEN_REQUEST_URL:
    "https://rec.mw.token-hub.docaposte-agility.tech/api/caih/request",
  HUB_SIGNED_REQUEST_URL:
    "https://rec.mw.token-hub.docaposte-agility.tech/api/caih/signedRequest",
  HUB_KEYCLOAK_REQUEST_URL:
    "http://rec.mw.token-hub.docaposte-agility.tech/api/caih/keycloakRequest",
  HUB_REQUEST_URL: "https://www.rec2.docapost.io/hub/v2/api/caih/",
  HUB_KEYCLOAK_DOMAIN:
    "https://hub.eco6.tech/auth/realms/CAIH_RECUEIL_ASSISTE/",
  HUB_KEYCLOAK_CLIENTID: "4dbe3faa9ce94db9b6680d2dd74d77d2",
  CAIH_DOMAIN: "https://caih-front.hub.docapost-agility.net/"
};

const prod = {
  HUB_TOKEN_REQUEST_URL:
    "https://prd.mw.token-hub.docaposte-agility.tech/api/caih/request",
  HUB_SIGNED_REQUEST_URL:
    "https://prd.mw.token-hub.docaposte-agility.tech/api/caih/signedRequest",
  HUB_KEYCLOAK_REQUEST_URL:
    "https://prd.mw.token-hub.docaposte-agility.tech/api/caih/keycloakRequest",
  HUB_REQUEST_URL: "https://docapost.io/hub/v2/api/caih/",
  HUB_KEYCLOAK_DOMAIN:
    "https://hub.eco6.tech/auth/realms/CAIH_RECUEIL_ASSISTE/",
  HUB_KEYCLOAK_CLIENTID: "4dbe3faa9ce94db9b6680d2dd74d77d2",
  CAIH_DOMAIN: "https://www.consentements.teleservices-sante-docaposte.fr/"
};

const getConfig = env => {
  switch (env) {
    case "development":
      return dev;
    case "recette":
      return recette;
    default:
      return prod;
  }
};

const config = getConfig(process.env.REACT_APP_STAGE);

export default config;
