import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery, Typography, Button } from "@mui/material";
import PreferenceIcon from "../../icons/PreferenceIcon";
import SignIcon from "../../icons/SignIcon";
import ModalUtil from "../../utils/ModalUtil";

const WelcomeModal = () => {
  const setModal = ModalUtil.useModal()[1];
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const closeModal = () => {
    setModal({
      isOpen: false,
      title: "",
      content: null,
      backdropReason: "click",
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <PreferenceIcon
        style={{
          height: 60,
          width: "auto",
          margin: 20,
          marginTop: 0,
          fill: "transparent",
        }}
      />
      <Typography
        variant={isMobile ? "subtitle2" : "subtitle1"}
        sx={{
          textAlign: "center",
          color: "cobalt.ultramarine",
        }}
      >
        {t("newConsent:welcomeModal:subtitle1")}
      </Typography>
      <SignIcon
        style={{ height: 60, width: "auto", margin: 24, fill: "transparent" }}
      />
      <Typography
        variant={isMobile ? "subtitle2" : "subtitle1"}
        sx={{ color: "cobalt.ultramarine" }}
      >
        {t("newConsent:welcomeModal:subtitle2")}
      </Typography>
      <Button fullWidth onClick={closeModal} sx={{ marginTop: "32px" }}>
        {t("common:start")}
      </Button>
    </Grid>
  );
};

export default WelcomeModal;
