import React from "react";
import PropTypes from "prop-types";
import { withTranslation, useTranslation } from "react-i18next";
import { Paper, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "da-design-system";

const ConsentLoginErrorBlock = (props) => {
  const { errorType } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Paper sx={{ borderRadius: "8px" }}>
      <Box
        pl={9}
        pr={8}
        pt={2}
        pb={2}
        sx={{
          position: "relative",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          borderLeft: "8px",
          borderLeftStyle: "solid",
          borderLeftColor: "cobalt.redText",
        }}
      >
        <CobaltIcon
          name="alert-circle"
          style={{
            color: theme.palette.cobalt.redIcon,
            position: "absolute",
            top: 16,
            left: 26,
          }}
        />
        {errorType === "ERROR" && (
          <Box>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:birthdate.error.label1")}
            </Typography>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:birthdate.error.label2")}
            </Typography>
          </Box>
        )}
        {errorType === "loginBirthdateError" && (
          <Box>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("login:errorLabel")}
            </Typography>
          </Box>
        )}
        {errorType === "TOO_MANY_ATTEMPTS" && (
          <Box>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:birthdate.error.label3")}
            </Typography>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:birthdate.error.label4")}
            </Typography>
          </Box>
        )}
        {errorType === "GENERAL_ERROR" && (
          <Box>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:birthdate.error.label5")}
            </Typography>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:birthdate.error.label4")}
            </Typography>
          </Box>
        )}
        {errorType === "UNREACHABLE" && (
          <Box>
            <Typography variant="body2" sx={{ color: "cobalt.lynch" }}>
              {t("consentLogin:userNotReachable")}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

ConsentLoginErrorBlock.propTypes = {
  errorType: PropTypes.string.isRequired,
};

export default withTranslation()(ConsentLoginErrorBlock);
