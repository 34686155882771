import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "da-design-system";

const CAIHInfoBlock = props => {
  const { label } = props;
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sx={{
        position: "relative",
        padding: "16px 16px 16px 56px",
        backgroundColor: "rgba(128, 153, 255, 0.08)",
        borderRadius: "8px",
        color: "cobalt.ultramarine",
        minHeight: "56px"
      }}
    >
      <CobaltIcon
        name="alert-circle"
        style={{
          fontSize: "20px",
          position: "absolute",
          left: 16,
          top: 23,
          color: theme.palette.cobalt.ultramarine
        }}
      />
      <Typography variant="body2">{label}</Typography>
    </Grid>
  );
};

CAIHInfoBlock.propTypes = {
  label: PropTypes.string.isRequired
};

export default CAIHInfoBlock;
