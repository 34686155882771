/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Dialog, Box, Slide, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "da-design-system";
import ModalUtil from "../utils/ModalUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} timeout={500} {...props} />;
});

const CustomModal = () => {
  const [modal, setModal] = ModalUtil.useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const {
    isOpen,
    content,
    title,
    hasSeparator,
    backdropReason,
    hasCloseIcon,
    size,
    isTitleCentered,
    transitionDuration
  } = modal;

  const getPaperModalStyle = mobileBreakpoint => {
    if (mobileBreakpoint) {
      return {
        borderRadius: "12px",
        position: "absolute",
        bottom: 0,
        margin: 0,
        width: "100vw",
        maxWidth: "100%",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px"
      };
    }
    return {
      borderRadius: "8px",
      boxShadow: "0px 12px 24px rgba(102,109, 146, 0.2)",
      webkitBoxShadow: "0px 12px 24px rgba(102,109, 146, 0.2)",
      mozBoxShadow: "0px 12px 24px rgba(102,109, 146, 0.2)"
    };
  };

  const closeModal = () => {
    setModal({
      isOpen: false,
      title: "",
      content: null,
      backdropReason: "click"
    });
  };

  return (
    <Dialog
      maxWidth={size}
      fullWidth
      TransitionComponent={Transition}
      transitionDuration={transitionDuration}
      open={isOpen}
      onClose={e => e.type && backdropReason && closeModal()}
      sx={{
        backgroundColor: "rgba(102,109,146, 0.3)"
      }}
      PaperProps={{
        sx: {
          ...getPaperModalStyle(isMobile)
        }
      }}
    >
      {hasCloseIcon && (
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer"
          }}
          onClick={() => closeModal()}
        >
          <CobaltIcon
            name="x"
            style={{ fontSize: "16px", color: "cobalt.ultramarine" }}
          />
        </Box>
      )}
      {title !== null && (
        <Box
          sx={{
            borderBottom: hasSeparator
              ? `solid 1px ${theme.palette.cobalt.grey10}`
              : "none",
            mt: "32px",
            mx: "32px"
          }}
        >
          <Typography
            variant={hasSeparator ? "h3" : "h4"}
            sx={{
              color: "cobalt.ultramarine",
              textAlign: isTitleCentered ? "center" : "left",
              paddingBottom: isMobile ? "16px" : "32px"
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Box sx={{ padding: "16px 32px 32px" }}>{content}</Box>
    </Dialog>
  );
};

export default CustomModal;
