import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Select,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

const CAIHSelect = (props) => {
  const { choices, placeholder, name, defaultValue } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { control, setValue } = useFormContext();

  const handleChange = (e) => {
    setValue(name, e.target.name);
    setIsOpen(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field: { value } }) => {
        return (
          <Select
            fullWidth
            open={isOpen}
            value={value}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            displayEmpty
            renderValue={() => {
              if (value.length === 0) {
                return placeholder;
              }
              return value;
            }}
            MenuProps={{
              sx: { mt: 1 },
              PaperProps: {
                style: {
                  position: "relative",
                  top: 2,
                  width: 250,
                  maxHeight: 250,
                },
              },
            }}
          >
            <FormControl
              sx={{ m: 2, mt: 1 }}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                {choices.map((choice) => (
                  <FormControlLabel
                    sx={{ p: 1, ml: 1 }}
                    key={choice}
                    control={
                      <Checkbox
                        checked={value === choice}
                        onChange={handleChange}
                        name={choice}
                      />
                    }
                    label={choice}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Select>
        );
      }}
    />
  );
};

CAIHSelect.defaultProps = {
  placeholder: "Sélectionnez",
  defaultValue: "",
};

CAIHSelect.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default CAIHSelect;
