import React from "react";
import { SvgIcon } from "@mui/material";

export default function PadlockIcon(props) {
  return (
    <SvgIcon
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M34.8611 19.25H7.63889C5.49111 19.25 3.75 21.0816 3.75 23.3409V37.6591C3.75 39.9184 5.49111 41.75 7.63889 41.75H34.8611C37.0089 41.75 38.75 39.9184 38.75 37.6591V23.3409C38.75 21.0816 37.0089 19.25 34.8611 19.25Z"
          stroke="#417CFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 19.25V11.4722C11.25 8.89373 12.3036 6.42084 14.1789 4.59757C16.0543 2.7743 18.5978 1.75 21.25 1.75C23.9022 1.75 26.4457 2.7743 28.3211 4.59757C30.1964 6.42084 31.25 8.89373 31.25 11.4722V19.25"
          stroke="#417CFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="33.5197" cy="37.2687" r="13.2306" fill="#EEF0FB" />
        <circle cx="33.5187" cy="37.2687" r="9.92298" fill="#E5EAFF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7024 29.1833C29.1355 29.1833 25.4333 32.8855 25.4333 37.4524C25.4333 42.0194 29.1355 45.7216 33.7024 45.7216C38.2694 45.7216 41.9716 42.0194 41.9716 37.4524C41.9716 32.8855 38.2694 29.1833 33.7024 29.1833ZM23.5957 37.4524C23.5957 31.8706 28.1206 27.3457 33.7024 27.3457C39.2842 27.3457 43.8092 31.8706 43.8092 37.4524C43.8092 43.0342 39.2842 47.5592 33.7024 47.5592C28.1206 47.5592 23.5957 43.0342 23.5957 37.4524Z"
          fill="#417CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.7852 42.0462C32.7852 41.5388 33.1965 41.1274 33.704 41.1274H33.7131C34.2206 41.1274 34.6319 41.5388 34.6319 42.0462C34.6319 42.5537 34.2206 42.965 33.7131 42.965H33.704C33.1965 42.965 32.7852 42.5537 32.7852 42.0462Z"
          fill="#417CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.9412 33.8001C33.5135 33.7267 33.0736 33.8071 32.6995 34.027C32.3254 34.2469 32.0411 34.5921 31.8971 35.0014C31.7287 35.4801 31.2042 35.7317 30.7255 35.5633C30.2468 35.3949 29.9953 34.8703 30.1636 34.3916C30.4517 33.5729 31.0201 32.8825 31.7684 32.4427C32.5167 32.003 33.3964 31.8422 34.2519 31.9889C35.1073 32.1357 35.8832 32.5804 36.4422 33.2444C37.001 33.9083 37.3069 34.7484 37.3057 35.6162C37.3053 37.0222 36.2629 37.9508 35.5184 38.4471C35.1182 38.7139 34.7244 38.9101 34.4344 39.039C34.2881 39.1041 34.1649 39.1534 34.0761 39.1872C34.0316 39.2042 33.9956 39.2173 33.9693 39.2266L33.9374 39.2378L33.9272 39.2413L33.9237 39.2425L33.9223 39.243C33.922 39.2431 33.9211 39.2434 33.6306 38.3717L33.9211 39.2434C33.4397 39.4038 32.9194 39.1437 32.7589 38.6623C32.5986 38.1812 32.8583 37.6612 33.3391 37.5004L33.3379 37.5008C33.338 37.5007 33.338 37.5007 33.3391 37.5004L33.3537 37.4952C33.3678 37.4902 33.391 37.4818 33.4219 37.47C33.4839 37.4464 33.576 37.4096 33.6881 37.3598C33.9149 37.259 34.2103 37.1107 34.4991 36.9181C35.1327 36.4957 35.4681 36.0464 35.4681 35.6153L35.4681 35.614C35.4688 35.18 35.3158 34.7598 35.0364 34.4278C34.7569 34.0958 34.3689 33.8734 33.9412 33.8001Z"
          fill="#417CFF"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
