import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography, Stack } from "@mui/material";
import Lottie from "react-lottie-player";
import loadingAnimation from "../animations/loading.json";

const CAIHLoader = props => {
  const { label, size } = props;
  const { t } = useTranslation();

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Lottie
        loop
        animationData={loadingAnimation}
        play
        style={{ width: size, height: size }}
      />
      <Box mt={2} sx={{ color: "cobalt.ultramarine" }}>
        <Typography variant="body2">
          {label === null ? t("common:loading") : label}
        </Typography>
      </Box>
    </Stack>
  );
};

CAIHLoader.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string
};

CAIHLoader.defaultProps = {
  label: null,
  size: "40px"
};

export default CAIHLoader;
