import axios from "axios";
import config from "../config";
import AuthService from "./AuthService";

const HubService = {
  // IPP AND IDCLINIC should be 2 in order to have the functionnal mock
  request: (
    method,
    url,
    body = null,
    isBoxNumber = false,
    isRepository = false,
    responseType = "json"
  ) => {
    const { HUB_TOKEN_REQUEST_URL } = config;

    return axios.post(HUB_TOKEN_REQUEST_URL, {
      method,
      url,
      body,
      isBoxNumber,
      isRepository,
      responseType
    });
  },
  requestOperator: (
    method,
    url,
    body = null,
    isBoxNumber = false,
    isRepository = false
  ) => {
    const { HUB_TOKEN_OPERATOR_REQUEST_URL } = config;

    return axios.post(HUB_TOKEN_OPERATOR_REQUEST_URL, {
      method,
      url,
      body,
      isBoxNumber,
      isRepository
    });
  },
  signedRequest: (
    method,
    url,
    body = null,
    isBoxNumber = false,
    isRepository = false
  ) => {
    const { HUB_SIGNED_REQUEST_URL } = config;
    const token = AuthService.getTokenOperator();

    return axios.post(HUB_SIGNED_REQUEST_URL, {
      headers: {
        Authorization: `bearer ${token}`
      },
      method,
      url,
      body,
      isBoxNumber,
      isRepository
    });
  },
  keycloakRequest: (
    method,
    url,
    body = null,
    isBoxNumber = false,
    isRepository = false
  ) => {
    const { HUB_KEYCLOAK_REQUEST_URL } = config;
    const token = AuthService.getTokenOperator();

    return axios.post(HUB_KEYCLOAK_REQUEST_URL, {
      headers: {
        Authorization: `bearer ${token}`,
        Accept: "applicatiion/json",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      method,
      url,
      body,
      isBoxNumber,
      isRepository
    });
  }
};

export default HubService;
