const getQueryParams = (url = window.location.href) => {
  const params = {};
  const search = decodeURI(url)
    .split("?")
    .slice(1)
    .join("?");

  search.split("&").forEach(param => {
    if (param) {
      const [key, value = ""] = param.split("=");
      if (!params[key]) {
        if (value && value.indexOf(",") !== -1) {
          params[key] = value.split(",");
        } else {
          params[key] = value;
        }
      }
    }
  });

  return params;
};

const createQueryParams = (params = {}) => {
  if (typeof params !== "object") {
    return "";
  }
  return Object.keys(params)
    .map(key => {
      if (Array.isArray(params[key])) {
        return `${key}=${params[key].join(",")}`;
      }
      return params[key] !== undefined ? `${key}=${params[key]}` : "";
    })
    .join("&");
};

export default {
  getQueryParams,
  createQueryParams
};
