/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Dialog, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} timeout={500} {...props} />;
});

const CobaltMobileModal = props => {
  const { isOpen, children } = props;

  return (
    <Dialog
      fullWidth
      TransitionComponent={Transition}
      transitionDuration={500}
      open={isOpen}
      PaperProps={{
        sx: {
          m: 0,
          position: "absolute",
          bottom: 0,
          width: "100vw"
        }
      }}
    >
      {children}
    </Dialog>
  );
};

CobaltMobileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default CobaltMobileModal;
