import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";

const LegalTextBlock = () => {
  const { t } = useTranslation();
  return (
    <Grid container flexDirection="column" mt={2}>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Rappel du texte de loi:
      </Typography>
      {Object.values(
        t("legalRepresentativeForm:articles", {
          returnObjects: true,
        })
      ).map(({ id: textId, title, items }) => {
        return (
          <Grid
            item
            mt={1}
            key={textId}
            sx={{
              fontWeight: "700",
              color: "cobalt.lynch",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "bold",
                color: "cobalt.lynch",
              }}
            >
              {title}
            </Typography>

            {items.map(({ id, text }) => {
              return (
                <Box
                  key={`${id}-text`}
                  sx={{
                    fontfamily: "Barlow",
                    fontSize: 14,
                    lineHeight: "16.8 px",
                    fontWeight: id === "1" && "400",
                    fontStyle: id === "2" || id === "1" ? "initial" : "italic",
                  }}
                >
                  {text}
                </Box>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LegalTextBlock;

// Bug deux parents non soulignes
//   const hello = {
//     _html: text.split(" ").map((s, i) => {
//       console.log(s, i);
//       return i === 16 || i === 17 ? `<u> ${s} </u>\n` : ` ${s}\n`;
//     }),
//   };
