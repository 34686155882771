import CustomHookFactory from "./CustomHookFactory";

export default CustomHookFactory("Modal", {
  isOpen: false,
  title: "",
  hasSeparator: true,
  isTitleCentered: false,
  content: null,
  backdropReason: "click",
  hasCloseIcon: true,
  size: "sm",
  transitionDuration: 300,
});
