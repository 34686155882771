import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../Layout";
import CAIHLoader from "../CAIHLoader";
import UrlUtils from "../../utils/UrlUtils";

const IFrameLoader = () => {
  // set le body en blanc pour correspondre au container parent de l'iframe
  document.getElementsByTagName("body")[0].style.backgroundColor = "white";

  const queryParams = UrlUtils.getQueryParams(window.location.href);
  const isUserActionDefined = queryParams.userAction !== undefined;

  const { t } = useTranslation();
  const label =
    isUserActionDefined && queryParams.userAction !== "OK"
      ? t("intervention:iframeActions:closing")
      : t("intervention:iframeActions:validate");

  return (
    <Layout noHeader>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "65vh", backgroundColor: "white" }}
      >
        <Box
          sx={{
            color: "cobalt.bleuDigital100"
          }}
        >
          {isUserActionDefined && queryParams.userAction !== "OK" ? (
            <Typography
              variant="subtitle1"
              sx={{ color: "cobalt.bleuDigital100" }}
            >
              {label}
            </Typography>
          ) : (
            <CAIHLoader
              label={label}
              size="20px"
              color="cobalt.bleuDigital100"
            />
          )}
        </Box>
      </Grid>
    </Layout>
  );
};

export default IFrameLoader;
