import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const FieldController = ({ placeholder, ...rest }) => {
  const { control } = useFormContext;
  return (
    <Controller
      {...rest}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextField
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            onChange={onChange}
            error={!!error}
            helperText={!error ? null : error.message}
            value={value}
          />
        );
      }}
    />
  );
};

FieldController.defaultProps = {
  placeholder: "",
};

FieldController.propTypes = {
  placeholder: PropTypes.string,
};

export default FieldController;
