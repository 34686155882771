import React from "react";
import { SvgIcon } from "@mui/material";

export default function SignIcon(props) {
  return (
    <SvgIcon
      width="61"
      height="63"
      viewBox="0 0 61 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6492 61.3697H3.89771C2.48831 61.3697 1.3457 60.2283 1.3457 58.8189V10.9734C1.3457 9.56399 2.48831 8.42139 3.89771 8.42139H28.2613L39.2001 19.0006V58.8189C39.2001 60.2283 38.0586 61.3697 36.6492 61.3697Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2617 8.42236V18.2105C28.2617 18.6455 28.6155 19.0004 29.0528 19.0004H39.2017"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70898 19.0004H17.1873"
        stroke="#E5EAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70898 25.8573H33.838"
        stroke="#E5EAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70898 31.1454H33.838"
        stroke="#E5EAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70898 36.4345H33.838"
        stroke="#E5EAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.70898 41.7236H33.838"
        stroke="#E5EAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.201 54.1866H43.4803C44.9001 54.1866 46.0509 53.0371 46.0509 51.6172V3.80885C46.0509 2.39016 44.9001 1.23828 43.4803 1.23828H10.7659C9.3472 1.23828 8.19531 2.39016 8.19531 3.80885V8.42218"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1508 47.9403L52.1908 47.9705L52.0898 25.1625L59.0499 25.1323L59.1508 47.9403Z"
        fill="#E5EAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1508 47.9403L52.1908 47.9705L52.0898 25.1625L59.0499 25.1323L59.1508 47.9403Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6966 53.7554L54.1445 50.7556L57.222 50.7417L55.6966 53.7554Z"
        fill="#417CFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6981 54.172L59.1514 47.9404L52.1914 47.9706L55.6981 54.172Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.0781 22.451L59.0381 22.4209"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.5685 24.7563L55.6149 35.1964"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.038 22.4201L52.0779 22.4503L52.0605 18.5585C52.0594 18.1293 52.4062 17.7789 52.8354 17.7766L58.2399 17.7534C58.6702 17.7523 59.0182 18.0979 59.0206 18.5283L59.038 22.4201Z"
        fill="#E5EAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.038 22.4201L52.0779 22.4503L52.0605 18.5585C52.0594 18.1293 52.4062 17.7789 52.8354 17.7766L58.2399 17.7534C58.6702 17.7523 59.0182 18.0979 59.0206 18.5283L59.038 22.4201Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0509 25.1321L52.0909 25.1623L52.0781 22.4351L59.0381 22.4038L59.0509 25.1321Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0509 25.1321L52.0909 25.1623L52.0781 22.4351L59.0381 22.4038L59.0509 25.1321Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
