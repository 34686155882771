const orderConsents = consents => {
  const regionalGroupingConsents = [];
  const hospitalConsents = [];

  consents.forEach(consent => {
    if (consent.regionalGrouping !== "") {
      regionalGroupingConsents.push(consent);
    } else {
      hospitalConsents.push(consent);
    }
  });

  const sortedConsents = [
    ...regionalGroupingConsents.sort(compare),
    ...hospitalConsents.sort(compare)
  ];

  return sortedConsents;
};

const compare = (a, b) => {
  const consentA = a.intentDisplayOrder;
  const consentB = b.intentDisplayOrder;

  let comparison = 0;

  if (consentA > consentB) {
    comparison = 1;
  } else if (consentB < consentA) {
    comparison = -1;
  }

  return comparison;
};

export default { orderConsents };
