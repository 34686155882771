import React from "react";
import PropTypes from "prop-types";
import { Box, Skeleton } from "@mui/material";

const TextSkeleton = props => {
  const { lines, width, isTitle, animation } = props;

  return (
    <Box>
      {[...Array(lines)].map((e, i) => (
        <Skeleton
          key={i.toString()}
          animation={animation}
          width={`${width}%`}
          height={isTitle ? 50 : 20}
        />
      ))}
    </Box>
  );
};

TextSkeleton.propTypes = {
  lines: PropTypes.number,
  width: PropTypes.string,
  isTitle: PropTypes.bool,
  animation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

TextSkeleton.defaultProps = {
  lines: 1,
  width: "100px",
  isTitle: false,
  animation: "wave"
};

export default TextSkeleton;
