import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import PatientConsent from "./PatientConsent";
import LinerConsent, {
  linerConsentProps as linerConsentTypes,
} from "./LinerConsent";
import { consentProps } from "../../types/Consent";

const ConsentDisplayDistribution = ({
  consents,
  isAutonomous,
  ...linerConsentProps
}) => {
  const { t } = useTranslation();
  const NO_OPPO_VALUE = "NO_OPPO";

  const noOppoConsent = consents.filter(
    consent =>
      consent.intentMode !== undefined && consent.intentMode === NO_OPPO_VALUE,
  );
  const regularConsents = consents.filter(
    consent =>
      consent.intentMode === undefined || consent.intentMode !== NO_OPPO_VALUE,
  );

  const haveOppositionalConsents = noOppoConsent.length > 0;
  const haveRegularConsents = regularConsents.length > 0;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={isAutonomous ? 0 : 3}
    >
      {haveOppositionalConsents && (
        <>
          <Grid item xs={12}>
            <Paper
              sx={{
                mt: isAutonomous ? 4 : 2,
                p: 4,
                backgroundColor: "cobalt.bleuDigital05",
                borderRadius: isAutonomous ? 0 : "inherit",
              }}
              elevation={isAutonomous && 0}
            >
              <Typography variant="h4" component="p">
                {t("consents:noOppoNotice")}
              </Typography>
            </Paper>
          </Grid>
          {noOppoConsent.map(consent =>
            isAutonomous ? (
              <LinerConsent
                key={consent.intentId}
                consent={consent}
                consentCount={noOppoConsent.length}
                {...linerConsentProps}
              />
            ) : (
              <PatientConsent
                key={consent.intentId}
                consent={consent}
                nonOppositionConsent
              />
            ),
          )}
        </>
      )}

      {haveRegularConsents && (
        <>
          <Grid item xs={12} p={isAutonomous && 0}>
            <Paper
              sx={{
                mt: isAutonomous && haveOppositionalConsents ? 2 : 4,
                p: 4,
                backgroundColor: "cobalt.bleuDigital05",
                borderRadius: isAutonomous ? 0 : "inherit",
              }}
              elevation={isAutonomous && 0}
            >
              <Typography variant="h4" component="p">
                {t("consents:regularConsentsNotice")}
              </Typography>
            </Paper>
          </Grid>
          {regularConsents.map(consent =>
            isAutonomous ? (
              <LinerConsent
                key={consent.intentId}
                consent={consent}
                consentCount={regularConsents.length}
                {...linerConsentProps}
              />
            ) : (
              <PatientConsent key={consent.intentId} consent={consent} />
            ),
          )}
        </>
      )}
    </Grid>
  );
};

export default ConsentDisplayDistribution;

ConsentDisplayDistribution.defaultProps = {
  isAutonomous: false,
};

ConsentDisplayDistribution.propTypes = {
  consents: PropTypes.arrayOf(consentProps).isRequired,
  isAutonomous: PropTypes.bool,
  ...linerConsentTypes,
};
