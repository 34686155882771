/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  ButtonBase,
  useMediaQuery,
  Alert,
  Button
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ConsentService from "../../services/ConsentService";
import ConsentsUtil from "../../utils/ConsentsUtil";
import SnackbarUtil from "../../utils/SnackbarUtil";
import ConsentContent from "../../components/consent/ConsentContent";
import ConsentGathering from "../../components/consent/ConsentGathering";
import LogoLayout from "../../components/LogoLayout";
import RequestUtils from "../../utils/RequestUtils";
import ConsentNote from "../../components/consent/ConsentNote";
import WelcomeModal from "../../components/newConsent/WelcomeModal";
import GDPRSuccessModal from "../../components/newConsent/GDPRSuccessModal";
import NewConsentErrorBlock from "../../components/newConsent/NewConsentErrorBlock";
import ModalUtil from "../../utils/ModalUtil";
import ClinicService from "../../services/ClinicService";

function NewConsent(props) {
  const { t } = useTranslation();
  const { location } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let { state } = location;
  const history = useHistory();
  const setSnackbar = SnackbarUtil.useSnackbar()[1];
  const setModal = ModalUtil.useModal()[1];
  const [isLoading, setIsLoading] = useState(true);
  const [isSendingConsent, setIsSendingConsent] = useState(false);
  const [clinicLogo, setClinicLogo] = useState("");
  const [clinicGHTLogo, setClinicGHTLogo] = useState("");
  const [clinicLegalNotice, setClinicLegalNotice] = useState("");
  const [clinicGtu, setClinicGtu] = useState("");
  const [newContent, setNewContent] = useState([]);
  const [consentsPatientId, setConsentsPatientId] = useState("");
  const [newConsents, setNewConsents] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedConsents, setSelectedConsents] = useState([]);
  const [fetchingError, setFetchingError] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [hasBothConsentType, setHasBothConsentType] = useState(false);
  const [medicalConsents, setMedicalConsents] = useState([]);
  // const [validatedConsents, setValidatedConsents] = useState([]);

  if (state === undefined) {
    state = {
      ipp: undefined,
      idClinic: undefined,
      name: undefined,
      clinicName: undefined,
      clinicLogo: undefined,
      birthdate: undefined,
      legalRepresentative: undefined,
      clinicGHTLogo: undefined,
      clinicLegalNotice: undefined,
      clinicGtu: undefined,
      hasBothConsentType: undefined
    };

    history.push("/");
  }

  const getAllPatientConsents = useCallback((ipp, idClinic) => {
    setIsLoading(true);
    ConsentService.getAllRequestedConsents(ipp, idClinic)
      .then(response => {
        setIsLoading(false);
        const { data } = response;
        const GDPRConsents = data.filter(
          consent =>
            consent.intentType === "GDPR" && consent.status === "REQUESTED"
        );

        const medConsents = data.filter(
          consent =>
            consent.intentType === "MEDICAL" && consent.status === "REQUESTED"
        );

        setMedicalConsents(medConsents);

        if (GDPRConsents.length > 0) {
          setConsentsPatientId(GDPRConsents[0].patientId);
        }

        setNewConsents(ConsentsUtil.orderConsents(GDPRConsents));
      })
      .catch(() => {
        setIsLoading(false);
        setFetchingError(true);
      });
  }, []);

  useEffect(() => {
    const getWelcomeModal = () => {
      setModal({
        isOpen: true,
        title: t("newConsent:welcomeModal:title"),
        isTitleCentered: true,
        content: <WelcomeModal />,
        backdropReason: "click",
        hasCloseIcon: false,
        size: "xs",
        transitionDuration: 500
      });
    };
    if (state.hasBothConsentType) {
      getWelcomeModal();
      setHasBothConsentType(state.hasBothConsentType);
    }
  }, [setModal, state.hasBothConsentType, t]);

  useEffect(() => {
    const { ipp, idClinic } = state;

    setClinicLegalNotice(state.clinicLegalNotice);
    setClinicGtu(state.clinicGtu);
    setClinicLogo(state.clinicLogo);
    setClinicGHTLogo(state.clinicGHTLogo);

    ClinicService.getClinicGDPRInformationNote(idClinic)
      .then(cinResponse => {
        const { data } = cinResponse;
        const GDPRInfoNote = JSON.parse(data[0].value);
        setNewContent(GDPRInfoNote.description);
      })
      .catch(() => setNewContent([]));

    getAllPatientConsents(ipp, idClinic);
  }, [state, getAllPatientConsents]);

  const handleChange = (consent, type) => {
    // Si le consentements n'est pas dans la liste des consentements déjà selectionnés
    if (!selectedConsents.includes(consent)) {
      // On le rajoute au tableau
      setSelectedConsents([...selectedConsents, consent]);
    }

    // Si le choix est oui
    if (type === "COMPLETED") {
      // Si le consentement n'est pas déjà dans le tableau des checkedValues
      if (!checkedValues.includes(consent)) {
        setCheckedValues([...checkedValues, consent]);
      }
    } else {
      setCheckedValues(checkedValues.filter(value => value !== consent));
    }
  };

  const checkEverything = () => {
    setCheckedValues(newConsents);
  };
  const uncheckEverything = () => {
    setCheckedValues([]);
  };

  const handleSubmit = () => {
    submitConsent();
  };

  const submitConsent = async () => {
    const promises = [];
    const results = [];
    const validatedConsents = [];
    const { legalRepresentative } = state;

    setIsSendingConsent(true);
    newConsents.forEach(newConsent => {
      const { patientId, intentId } = newConsent;
      const consentId = `${patientId}_${intentId}`;

      const body = {
        status: checkedValues.includes(newConsent) ? "COMPLETED" : "REFUSED",
        consentCollectorCategoryCode:
          legalRepresentative === "yes" ? "LEGAL_REPRESENTATIVE" : "PATIENT"
      };

      promises.push(ConsentService.updatePatientConsents(consentId, body));
    });

    await Promise.all(
      promises.map(p =>
        p
          .then(result => {
            const { status } = result;
            results.push(status);
            // Si le consentement a été validé : on l'ajoute au tableau des consentements validés
            if (status === 200) {
              const validatedConsentId = JSON.parse(result.config.data);
              const consentId = validatedConsentId.url.split("_")[1];
              const validatedConsent = newConsents.filter(
                consent => consent.intentId === consentId
              );
              const [consent] = validatedConsent;
              validatedConsents.push(consent);
            }
          })
          .catch(e => {
            if (e.response.status) {
              results.push(e.response.status);
            }
          })
      )
    );

    // Si l'un des status est un succes on envoie un mail
    if (results.includes(200)) {
      ConsentService.sendEmailConfirmation(consentsPatientId, "GDPR")
        .then(emailResponse => {
          const emailStatus = emailResponse.status;

          // Si le mail a été envoyé
          if (RequestUtils.isSuccess(emailStatus)) {
            // Si tous les promises ont retourné un success
            if (results.every(pStatus => pStatus === 200)) {
              setIsSendingConsent(false);
              // Si l'utilisateur doit également remplir un autre type de consentement, on ouvre une modale pour passer à la seconde étape
              if (hasBothConsentType) {
                setModal({
                  isOpen: true,
                  title: null,
                  content: (
                    <GDPRSuccessModal
                      consentParams={state}
                      medicalConsents={medicalConsents}
                    />
                  ),
                  backdropReason: null,
                  hasCloseIcon: false,
                  size: "xs"
                });
              } else {
                history.push("/consent/success", {
                  clinicLogo,
                  clinicGHTLogo,
                  clinicLegalNotice,
                  clinicGtu
                });
              }
            } else {
              const statusWithErrorArray = [];
              const newRequestedConsents = [];

              results.forEach(statusResult => {
                if (statusResult !== 200) {
                  statusWithErrorArray.push(statusResult);
                }
              });

              newConsents.forEach(consent => {
                if (!validatedConsents.includes(consent)) {
                  newRequestedConsents.push(consent);
                }
              });

              setSnackbar({
                isOpen: true,
                message: "Au moins un consentement n'a pu être recueilli",
                type: "error"
              });
              setIsSendingConsent(false);
              setErrorCount(errorCount + 1);
              setNewConsents(newRequestedConsents);
              setSelectedConsents(newRequestedConsents);
            }
          }
        })
        .catch(() => {
          setSnackbar({
            isOpen: true,
            message: t("layout:alert.mail"),
            type: "error"
          });
          setIsSendingConsent(false);
          setErrorCount(errorCount + 1);
        });
    } else {
      const statusWithErrorArray = [];

      results.forEach(statusResult => {
        if (statusResult !== 200) {
          statusWithErrorArray.push(statusResult);
        }
      });

      setSnackbar({
        isOpen: true,
        message: t("layout:alert.error"),
        type: "error"
      });

      setIsSendingConsent(false);
      setErrorCount(errorCount + 1);
    }
  };

  const everythingIsChecked = () =>
    newConsents.length === selectedConsents.length;
  const formatedBirthdate = format(new Date(state.birthdate), "dd.MM.yyyy");

  return (
    <LogoLayout
      logo={clinicLogo}
      legalNotice={clinicLegalNotice}
      gtu={clinicGtu}
    >
      <Box>
        <Box mb={isMobile ? 3 : 1}>
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justifyContent="flex-start"
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Typography
              variant="h1"
              sx={{
                marginRight: isMobile ? "0px" : "20px",
                color: "cobalt.ultramarine"
              }}
            >
              {state.name}
            </Typography>
            {state.birthdate !== undefined && (
              <Typography
                variant={isMobile ? "subtitle2" : "subtitle1"}
                sx={{ color: "cobalt.lynch" }}
              >
                {`Né(e) le ${formatedBirthdate}`}
              </Typography>
            )}
          </Grid>
        </Box>
        {!isLoading && !fetchingError && newConsents.length === 0 && (
          <Box>
            <Alert type="success">{t("newConsent:noConsents")}</Alert>
            <Box mt={2}>
              <Grid container justifyContent="center" alignItems="center">
                <ButtonBase
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <Typography variant="caption" color="primary" align="center">
                    {t("newConsent:return")}
                  </Typography>
                </ButtonBase>
              </Grid>
            </Box>
          </Box>
        )}
        {isLoading && (
          <Box>
            <Box mt={5} mb={3}>
              <ConsentContent
                isLoading={isLoading}
                content={newContent}
                title={{ titleLabel: t("newConsent:infoNote") }}
              />
            </Box>
            <Box mb={3}>
              <ConsentGathering
                isLoading={isLoading}
                newConsents={newConsents}
                checkedValues={checkedValues}
                changeConsentFunc={handleChange}
                checkEverythingFunc={checkEverything}
                uncheckEverythingFunc={uncheckEverything}
              />
            </Box>
            <Box mb={3}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Button
                  disabled={isLoading || isSendingConsent}
                  onClick={handleSubmit}
                  fullWidth={isMobile}
                >
                  {t("newConsent:validate")}
                </Button>
              </Grid>
            </Box>
            <Box mb={2}>
              <ConsentNote />
            </Box>
          </Box>
        )}
        {!isLoading && !fetchingError && newConsents.length > 0 && (
          <Box>
            {newContent === null ||
            (newContent.length === 1 && newContent[0] === "") ||
            newContent.length === 0 ? (
              <Box />
            ) : (
              <Box mt={5} mb={3}>
                <ConsentContent
                  isLoading={isLoading}
                  content={newContent}
                  title={{ titleLabel: t("newConsent:infoNote") }}
                />
              </Box>
            )}
            <Box mb={3}>
              <ConsentGathering
                isLoading={isLoading}
                newConsents={newConsents}
                checkedValues={checkedValues}
                changeConsentFunc={handleChange}
                checkEverythingFunc={checkEverything}
                uncheckEverythingFunc={uncheckEverything}
              />
            </Box>
            <Box mb={3}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Button
                  fullWidth={isMobile}
                  disabled={
                    isLoading || isSendingConsent || !everythingIsChecked()
                  }
                  onClick={handleSubmit}
                >
                  {t("newConsent:validate")}
                </Button>
              </Grid>
            </Box>
            <Box mb={2}>
              <ConsentNote />
            </Box>
          </Box>
        )}
        {!isLoading && fetchingError && (
          <Box>
            <NewConsentErrorBlock />
            <Box mt={10}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => {
                    setIsLoading(true);
                    setErrorCount(errorCount + 1);
                  }}
                >
                  Réessayer
                </Button>
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </LogoLayout>
  );
}

NewConsent.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      ipp: PropTypes.string.isRequired,
      idClinic: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      clinicName: PropTypes.string.isRequired,
      clinicLogo: PropTypes.string.isRequired,
      birthdate: PropTypes.instanceOf(Date).isRequired,
      legalRepresentative: PropTypes.string.isRequired,
      clinicGHTLogo: PropTypes.string.isRequired,
      clinicLegalNotice: PropTypes.string.isRequired,
      clinicGtu: PropTypes.string.isRequired,
      hasBothConsentType: PropTypes.bool.isRequired
    })
  }).isRequired
};

export default NewConsent;
