/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types";

export const consentProps = PropTypes.shape({
  intentId: PropTypes.string,
  intentLabel: PropTypes.string,
  intentDescription: PropTypes.string,
  statusDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  status: PropTypes.string,
}).isRequired;
