import React from "react";
import { SvgIcon } from "@mui/material";

export default function ShieldIcon(props) {
  return (
    <SvgIcon
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.1602 49.9445C22.1602 49.9445 41.6544 40.1974 41.6544 25.5767V9.32217L22.1602 1.20898L2.66602 9.32217V17.4495V25.5767C2.66602 40.1974 22.1602 49.9445 22.1602 49.9445Z"
        stroke="#417CFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="36.25" cy="34.875" r="15.625" fill="#EEF0FB" />
      <path
        d="M43.023 24.9099L46.2147 28.1019C47.0951 28.9823 47.0951 30.4147 46.2147 31.2951L35.574 41.9367L28.125 43L29.1893 35.5515L39.8301 24.9099C40.7081 24.0295 42.1404 24.0306 43.023 24.9099Z"
        fill="#E5EAFF"
        stroke="#417CFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 18.75C16.3291 18.75 18.2188 16.9023 18.2188 14.625C18.2188 12.3477 16.3291 10.5 14 10.5C11.6709 10.5 9.78125 12.3477 9.78125 14.625C9.78125 16.9023 11.6709 18.75 14 18.75ZM17.75 20H16.1357C15.4854 20.2922 14.7617 20.4583 14 20.4583C13.2383 20.4583 12.5176 20.2922 11.8643 20H10.25C8.17871 20 6.5 21.6414 6.5 23.6667V24.125C6.5 24.8841 7.12988 25.5 7.90625 25.5H20.0938C20.8701 25.5 21.5 24.8841 21.5 24.125V23.6667C21.5 21.6414 19.8213 20 17.75 20Z"
        fill="#E5EAFF"
      />
    </SvgIcon>
  );
}
