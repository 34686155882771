export const PUBLIC_ROUTING = [
  {
    path: "/",
    key: "login",
  },
  {
    path: "/consent",
    key: "consent-login",
  },
  {
    path: "/information",
    key: "information",
  },
  {
    path: "/consent/new",
    key: "new-consent",
  },
  {
    path: "/consent/success",
    key: "consent-success",
  },
  {
    path: "/operator",
    key: "connect-operator",
  },
  {
    path: "/operator/connection",
    key: "connection-operator",
  },
  {
    path: "/consent/intervention",
    key: "interventions",
  },
  {
    path: "/consent/intervention/sign",
    key: "intervention-sign",
  },
  {
    path: "/consent/intervention/signed",
    key: "intervention-signed",
  },
  {
    path: "/consent/intervention/validate",
    key: "intervention-validate",
  },
  {
    path: "/information/complete",
    key: "form",
  },
];

export const PRIVATE_PATIENT_ROUTING = [
  {
    path: "/consents",
    key: "consents",
  },
  {
    path: "/consent",
    key: "consent-login",
  },
];

export default {
  PUBLIC_ROUTING,
  PRIVATE_PATIENT_ROUTING,
};
