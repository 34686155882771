import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Grid, useMediaQuery, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ConsentService from "../../services/ConsentService";
import ModalUtil from "../../utils/ModalUtil";
import requestUtils from "../../utils/RequestUtils";
import SnackbarUtil from "../../utils/SnackbarUtil";

const ConsentAcceptModal = props => {
  const { t } = useTranslation();
  const { consent, setPatientConsentFunc, nonOppositionConsent } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const setModal = ModalUtil.useModal()[1];
  const setSnackbar = SnackbarUtil.useSnackbar()[1];
  const [isFetchingData, setIsFetchingData] = useState(false);

  const closeModal = () => {
    setModal({
      isOpen: false,
      title: "",
      content: null,
      backdropReason: "click",
    });
  };

  const openAlert = (message, type) => {
    setSnackbar({ isOpen: true, message, type });
  };

  const updateConsent = status => {
    const { patientId, intentId } = consent;
    const consentId = `${patientId}_${intentId}`;

    setIsFetchingData(true);

    const body = {
      status,
      consentCollectorCategoryCode: "PATIENT",
    };

    ConsentService.updatePatientConsents(consentId, body)
      .then(response => {
        const responseStatus = response.status;

        if (requestUtils.isSuccess(responseStatus)) {
          ConsentService.sendEmailConfirmation(patientId, "GDPR")
            .then(emailResponse => {
              const emailStatus = emailResponse.status;

              if (requestUtils.isSuccess(emailStatus)) {
                setIsFetchingData(false);
                const newConsent = consent;
                newConsent.status = status;
                newConsent.statusDate = new Date();
                setPatientConsentFunc(newConsent);
                closeModal();
                openAlert(t("layout:alert.success"), "success");
              }
            })
            .catch(() => {
              setIsFetchingData(false);
              const newConsent = consent;
              newConsent.status = status;
              newConsent.statusDate = new Date();
              setPatientConsentFunc(newConsent);
              closeModal();
              openAlert(t("layout:alert.mail"), "error");
            });
        }
      })
      .catch(() => {
        setIsFetchingData(false);
        closeModal();
        openAlert(t("layout:alert.error"), "error");
      });
  };

  return (
    <Box>
      <Box mt={3} mb={1}>
        <Typography variant="subtitle1">
          {nonOppositionConsent
            ? t("consents:acceptModal.content.noOppo")
            : t("consents:acceptModal.content.consent")}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="body2">{consent.intentLabel}</Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {isMobile ? (
          <Box>
            <Button
              variant="CAIHLiteContained"
              disabled={isFetchingData}
              onClick={() => updateConsent("COMPLETED")}
            >
              {t("common:accept")}
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              variant="outlined"
              disabled={isFetchingData}
              onClick={() => closeModal()}
              sx={{ marginRight: "16px" }}
            >
              {t("common:cancel")}
            </Button>
            <Button
              disabled={isFetchingData}
              onClick={() => updateConsent("COMPLETED")}
            >
              {t("common:accept")}
            </Button>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

ConsentAcceptModal.defaultProps = {
  nonOppositionConsent: false,
};

ConsentAcceptModal.propTypes = {
  consent: PropTypes.shape({
    patientId: PropTypes.string.isRequired,
    intentId: PropTypes.string.isRequired,
    intentLabel: PropTypes.string.isRequired,
  }).isRequired,
  setPatientConsentFunc: PropTypes.func.isRequired,
  nonOppositionConsent: PropTypes.bool,
};

export default ConsentAcceptModal;
