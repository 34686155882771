import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation, useTranslation } from "react-i18next";
import format from "date-fns/format";
import { Grid, Paper, Box, Typography, Button } from "@mui/material";
import CAIHPrediceError from "../CAIHPrediceError";
import ConsentKnowMore from "./ConsentKnowMore";
import ConsentChoiceModal from "./ConsentChoiceModal";
import ConsentRevokeModal from "./ConsentRevokeModal";
import ConsentAcceptModal from "./ConsentAcceptModal";
import ModalUtil from "../../utils/ModalUtil";
import TableUtils from "../../utils/TableUtils";

const PatientConsent = props => {
  const { t } = useTranslation();
  const setModal = ModalUtil.useModal()[1];
  const { consent, nonOppositionConsent } = props;
  const [patientConsent, setPatientConsent] = useState(consent);
  const statusIcons = TableUtils.cellStatusElements();

  const getIcon = elemStatus =>
    statusIcons.find(cellElem => cellElem.type === elemStatus).icon;

  const getKnowMore = () => {
    setModal({
      isOpen: true,
      title: t("consents:knowMore"),
      content: (
        <ConsentKnowMore
          title={patientConsent.intentLabel}
          description={patientConsent.intentDescription}
        />
      ),
      backdropReason: "click",
    });
  };

  const getChoiceModal = btnId => {
    document.getElementById(btnId).blur();
    setModal({
      isOpen: true,
      title: t("consents:choiceModal.title"),
      content: (
        <ConsentChoiceModal
          consent={patientConsent}
          setPatientConsentFunc={setPatientConsent}
          nonOppositionConsent={nonOppositionConsent}
        />
      ),
      backdropReason: "click",
    });
  };

  const getRevokeModal = btnId => {
    document.getElementById(btnId).blur();
    setModal({
      isOpen: true,
      title: nonOppositionConsent
        ? t("consents:revokeModal.title.noOppo")
        : t("consents:revokeModal.title.consent"),
      content: (
        <ConsentRevokeModal
          consent={patientConsent}
          setPatientConsentFunc={setPatientConsent}
          nonOppositionConsent={nonOppositionConsent}
        />
      ),
      backdropReason: "click",
    });
  };

  const getAcceptModal = btnId => {
    console.log(
      t("consents:acceptModal.title.noOppo"),
      t("consents:acceptModal.title.consent"),
    );
    document.getElementById(btnId).blur();
    setModal({
      isOpen: true,
      title: nonOppositionConsent
        ? t("consents:acceptModal.title.noOppo")
        : t("consents:acceptModal.title.consent"),
      content: (
        <ConsentAcceptModal
          consent={patientConsent}
          setPatientConsentFunc={setPatientConsent}
          nonOppositionConsent={nonOppositionConsent}
        />
      ),
      backdropReason: "click",
    });
  };

  return (
    <Grid item sm={6} sx={{ position: "relative" }}>
      <Paper sx={{ height: "100%", marginBottom: "60px" }}>
        <Box py={4} px={5}>
          <Box mb={4}>
            <Typography variant="body2">{consent.intentLabel}</Typography>
            {consent.intentDescription !== "" &&
              consent.intentDescription !== null && (
                <Typography
                  variant="body2"
                  onClick={() => getKnowMore()}
                  sx={{ color: "cobalt.bleuDigital100", cursor: "pointer" }}
                >
                  En savoir plus
                </Typography>
              )}
          </Box>
          <Grid
            sx={{
              position: "absolute",
              bottom: 35,
              left: 50,
              right: 50,
              width: "calc(100% - 100px)",
            }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {consent.status !== "MAINCARE_READ_ERROR" ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {consent.status !== "REQUESTED"
                      ? format(new Date(consent.statusDate), "dd.MM.yyyy")
                      : "-"}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <Box mr={1}>
                      <Typography
                        variant="h5"
                        sx={{ color: "cobalt.bleuDigital100" }}
                      >
                        {t(`cell:status.${consent.status}`)}
                      </Typography>
                    </Box>
                    {getIcon(consent.status)}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {consent.status === "REQUESTED" && (
                      <Button
                        id={`button-request-${consent.intentId}`}
                        onClick={() =>
                          getChoiceModal(`button-request-${consent.intentId}`)
                        }
                      >
                        {t("common:choose")}
                      </Button>
                    )}
                    {consent.status === "COMPLETED" && (
                      <Button
                        id={`button-revoke-${consent.intentId}`}
                        variant="CAIHLite"
                        onClick={() =>
                          getRevokeModal(`button-revoke-${consent.intentId}`)
                        }
                      >
                        {nonOppositionConsent
                          ? t("common:refuse")
                          : t("common:revoke")}
                      </Button>
                    )}
                    {consent.status === "REFUSED" && (
                      <Button
                        variant="CAIHLite"
                        id={`button-accept-${consent.intentId}`}
                        onClick={() =>
                          getAcceptModal(`button-accept-${consent.intentId}`)
                        }
                      >
                        {t("common:accept")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <CAIHPrediceError />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

PatientConsent.defaultProps = {
  nonOppositionConsent: false,
};

PatientConsent.propTypes = {
  consent: PropTypes.shape({
    intentId: PropTypes.string,
    intentLabel: PropTypes.string,
    intentDescription: PropTypes.string,
    statusDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    status: PropTypes.string,
  }).isRequired,
  nonOppositionConsent: PropTypes.bool,
};

export default withTranslation()(PatientConsent);
