/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  useMediaQuery,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CAIHCard from "../CAIHCard";
import TextSkeleton from "../TextSkeleton";
import ConsentKnowMore from "./ConsentKnowMore";
import ModalUtil from "../../utils/ModalUtil";
import ConsentDisplayDistribution from "./ConsentDisplayDistribution";
import { consentProps } from "../../types/Consent";

function ConsentGathering(props) {
  const { t } = useTranslation();
  const [selectedConsents, setSelectedConsents] = useState([]);
  const setModal = ModalUtil.useModal()[1];
  const {
    isLoading,
    isInCard,
    revokedDisabled,
    newConsents,
    checkedValues,
    changeConsentFunc,
    checkEverythingFunc,
    uncheckEverythingFunc,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const everythingIsChecked = () => newConsents.length === checkedValues.length;

  const getKnowMore = consent => {
    setModal({
      isOpen: true,
      title: "En savoir plus :",
      content: (
        <ConsentKnowMore
          title={consent.intentLabel}
          description={consent.intentDescription}
        />
      ),
      backdropReason: "click",
    });
  };

  const checkOrUncheckEverything = () => {
    if (everythingIsChecked()) {
      uncheckEverythingFunc();
    } else {
      checkEverythingFunc();
    }
  };

  const handleChange = (newConsent, type) => {
    if (!selectedConsents.includes(newConsent)) {
      setSelectedConsents([...selectedConsents, newConsent]);
    }

    changeConsentFunc(newConsent, type);
  };

  if (isInCard) {
    return (
      <CAIHCard hasPadding={false} cardContentNoPadding>
        {isLoading ? (
          <Box p={4}>
            <TextSkeleton lines={4} width={isMobile ? "100" : "70"} />
          </Box>
        ) : (
          <Box>
            <Box ml={4} mt={4} mr={4}>
              <Typography variant="h3">
                {t("newConsent:consentsFull")}
              </Typography>
            </Box>
            <ConsentDisplayDistribution
              consents={newConsents}
              isAutonomous
              onClick={getKnowMore}
              handleChange={handleChange}
              checkedValues={checkedValues}
              selectedConsents={selectedConsents}
            />
          </Box>
        )}
      </CAIHCard>
    );
  }

  return (
    <Box>
      {isLoading ? (
        <TextSkeleton lines={4} width={isMobile ? "100" : "70"} />
      ) : (
        <Box>
          {checkEverythingFunc !== null && uncheckEverythingFunc !== null && (
            <Box mb={2}>
              <FormControl>
                <FormGroup>
                  <Box mb={1}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={everythingIsChecked()}
                          onChange={() => {
                            checkOrUncheckEverything();
                          }}
                          color="primary"
                        />
                      }
                      label="Tout sélectionner"
                    />
                  </Box>
                </FormGroup>
              </FormControl>
            </Box>
          )}
          <FormControl>
            <FormGroup>
              {newConsents.map(newConsent => (
                <Box mb={1} key={newConsent.intentId}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedValues.includes(newConsent)}
                        disabled={
                          revokedDisabled && newConsent.status === "REFUSED"
                        }
                        onChange={() => {
                          changeConsentFunc(newConsent);
                        }}
                        color="primary"
                      />
                    }
                    label={newConsent.intentDescription}
                  />
                </Box>
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </Box>
  );
}

ConsentGathering.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isInCard: PropTypes.bool,
  revokedDisabled: PropTypes.bool,
  newConsents: PropTypes.arrayOf(consentProps).isRequired,
  checkedValues: PropTypes.arrayOf(consentProps).isRequired,
  changeConsentFunc: PropTypes.func.isRequired,
  checkEverythingFunc: PropTypes.func,
  uncheckEverythingFunc: PropTypes.func,
};

ConsentGathering.defaultProps = {
  isInCard: true,
  revokedDisabled: false,
  checkEverythingFunc: null,
  uncheckEverythingFunc: null,
};

export default ConsentGathering;
