import HubService from "./HubService";

const PatientService = {
  getPatientByToken: (token) =>
    HubService.request("GET", `contexts/${token}?inputMode=autonomous`),
  getSignature: (signatures, token) =>
    HubService.request("POST", "consentSignatures", {
      consentList: signatures,
      contextId: token,
    }),
  updatePatientLegalRepresentatives: (representativeData) =>
    HubService.request("POST", `consentUpdate`, {
      ...representativeData,
    }),
};

export default PatientService;
