import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Box } from "@mui/material";
import TextSkeleton from "../TextSkeleton";

const PatientConsentLoading = props => {
  const { fullWidth } = props;
  return (
    <Grid item xs={12} sm={fullWidth ? 12 : 6} style={{ position: "relative" }}>
      <Paper style={{ height: "100%", marginBottom: "60px" }}>
        <Box py={4} px={5}>
          <Box mb={4}>
            <TextSkeleton />
            <Grid item xs={3}>
              <TextSkeleton />
            </Grid>
          </Box>
          <Grid
            style={{
              position: "absolute",
              bottom: 35,
              left: 50,
              right: 50,
              width: "calc(100% - 100px)"
            }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={fullWidth ? 9 : 3}>
              {!fullWidth && (
                <Box>
                  <TextSkeleton />
                  <TextSkeleton />
                </Box>
              )}
            </Grid>
            <Grid item xs={fullWidth ? 3 : 9}>
              {fullWidth && (
                <Box>
                  <TextSkeleton />
                  <TextSkeleton />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

PatientConsentLoading.propTypes = {
  fullWidth: PropTypes.bool
};

PatientConsentLoading.defaultProps = {
  fullWidth: false
};

export default PatientConsentLoading;
