import React from "react";
import PropTypes from "prop-types";
import { withTranslation, useTranslation } from "react-i18next";
import {
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from "@mui/material";

const formControlLabelStyle = {
  "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
    color: "cobalt.lynch",
    fontFamily: "'Barlow', sans-serif",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  "&.MuiRadio-colorPrimary.Mui-checked": {
    color: "cobalt.userBlue"
  },
  "&.MuiRadio-root": {
    color: "cobalt.lynch"
  },
  "&.MuiIconButton-colorPrimary:hover": {
    backgroundColor: "transparent"
  },
  "&.MuiFormControlLabel-root": {
    padding: "13px 0px 2px 0px"
  }
};

const ConsentLoginSuccessBlock = props => {
  const { t } = useTranslation();
  const { name, legalRepresentative, setLegalRepresentative } = props;

  const handleRadio = event => {
    setLegalRepresentative(event.target.value);
  };

  return (
    <Box>
      <Box mb={4} ml={1.5}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="legal-representative"
            name="legal-representative"
            value={legalRepresentative}
            onChange={handleRadio}
          >
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label={`${t("consentLogin:birthdate.success.radioNo")} ${name}`}
              labelPlacement="end"
              sx={{ ...formControlLabelStyle }}
            />
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label={t("consentLogin:birthdate.success.radioYes")}
              labelPlacement="end"
              sx={{ ...formControlLabelStyle }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

ConsentLoginSuccessBlock.propTypes = {
  name: PropTypes.string.isRequired,
  legalRepresentative: PropTypes.string.isRequired,
  setLegalRepresentative: PropTypes.func.isRequired
};

export default withTranslation()(ConsentLoginSuccessBlock);
