import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Tooltip, IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "da-design-system";

const FieldItem = (props) => {
  const { label, children, withTooltip, tooltipContent, ...rest } = props;
  const theme = useTheme();

  return (
    <Grid container flexDirection="column" flexGrow={1} spacing={1} {...rest}>
      <Grid item>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle2">{label}</Typography>
          {withTooltip && (
            <Tooltip
              title={tooltipContent}
              placement="right"
              arrow
              sx={{ ml: 0.5 }}
            >
              <IconButton
                aria-label="tooltip"
                size="small"
                disableRipple
                disableFocusRipple
              >
                <CobaltIcon
                  name="info"
                  style={{
                    fontSize: 14,
                    color: theme.palette.cobalt.ultramarine,
                    "&:hover": {
                      color: theme.palette.cobalt.userBlue,
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

FieldItem.defaultProps = {
  label: "",
  withTooltip: false,
  tooltipContent: "",
};

FieldItem.propTypes = {
  label: PropTypes.string,
  withTooltip: PropTypes.bool,
  tooltipContent: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default FieldItem;
