import React, { useState, useEffect } from "react";
import { Formiz, useForm } from "@formiz/core";
import { isRequired } from "@formiz/validations";
import {
  Box,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import { CobaltIcon } from "da-design-system";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import CAIHField from "../../components/CAIHField";
import CAIHCard from "../../components/CAIHCard";
import CAIHDatepicker from "../../components/CAIHDatepicker";
import OtpModal from "../../components/login/OtpModal";
import FieldValidationUtil from "../../utils/FieldValidationUtil";
import ModalUtil from "../../utils/ModalUtil";
import ClinicService from "../../services/ClinicService";
import ConsentService from "../../services/ConsentService";
import Layout from "../../components/Layout";
import SnackbarUtil from "../../utils/SnackbarUtil";
import IppTooltip from "../../components/login/IppTooltip";
import ConsentLoginErrorBlock from "../../components/consentLogin/ConsentLoginErrorBlock";

function Login() {
  const { t } = useTranslation();
  const authentForm = useForm();
  const setModal = ModalUtil.useModal()[1];
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clinics, setClinics] = useState([]);
  const [isDataNotReachable, setIsDataNotReachable] = useState(false);
  const [chosenClinic, setChosenClinic] = useState("");
  const [authentError, setAuthentError] = useState(false);
  const [authentErrorType, setAuthentErrorType] = useState("");

  const setSnackbar = SnackbarUtil.useSnackbar()[1];

  useEffect(() => {
    ClinicService.getClinics().then(response => {
      const { data } = response;
      const clinicsArray = [];

      data.forEach(clinicObject => {
        const item = JSON.parse(clinicObject.value);
        const { shortName, hidden } = item;

        if (!hidden) {
          clinicsArray.push({
            healthFacilityId: clinicObject.k1,
            shortName,
          });
        }
      });

      const sortedClinics = clinicsArray.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.shortName > b.shortName ? 1 : b.shortName > a.shortName ? -1 : 0,
      );

      setClinics(sortedClinics);
      setIsLoading(false);
    });
  }, []);

  const changeClinic = event => {
    const { value } = event.target;

    setChosenClinic(value === "" ? null : value);
  };

  const handleSubmit = () => {
    const { values } = authentForm;
    const { ipp, birthdate } = values;
    const patientBirthdate = format(birthdate, "yyyy-MM-dd");

    setIsFetching(true);
    ConsentService.getPatientByBirthdate(patientBirthdate, ipp, chosenClinic)
      .then(response => {
        setAuthentError(false);
        setIsFetching(false);
        const { data } = response;

        const isLegalRepresentativeEmpty =
          Object.keys(data.legalRepresentative).length === 0 &&
          data.legalRepresentative.constructor === Object;

        if (data.email === undefined || data.email === null) {
          data.email = "";
        }

        if (data.mobileNumber === undefined || data.mobileNumber === null) {
          data.mobileNumber = "";
        }

        if (isLegalRepresentativeEmpty) {
          data.legalRepresentative.email = "";
          data.legalRepresentative.mobileNumber = "";
        }

        if (
          data.legalRepresentative.email === undefined ||
          data.legalRepresentative.email === null
        ) {
          data.legalRepresentative.email = "";
        }

        if (
          data.legalRepresentative.mobileNumber === undefined ||
          data.legalRepresentative.mobileNumber === null
        ) {
          data.legalRepresentative.mobileNumber = "";
        }

        const cantReachUser = data.email === "" || data.mobileNumber === "";

        const cantReachLegalRepresentative =
          data.legalRepresentative.email === "" ||
          data.legalRepresentative.mobileNumber === "" ||
          isLegalRepresentativeEmpty;

        const cantReachBoth = cantReachLegalRepresentative && cantReachUser;

        if (cantReachBoth) {
          setIsDataNotReachable(true);
        } else {
          setModal({
            isOpen: true,
            title: t("login:modalTitle"),
            hasSeparator: true,
            content: <OtpModal user={data} />,
            backdropReason: null,
          });
        }
      })
      .catch(error => {
        const { response } = error;
        const { data, status } = response;

        setIsFetching(false);

        if (status === 404 && data.errorMessage === "PATIENT_NOT_FOUND") {
          setAuthentError(true);
          setAuthentErrorType("loginBirthdateError");
        } else if (status === 500) {
          setAuthentError(true);
          if (data.errorMessage === "TOO_MANY_ATTEMPTS") {
            setAuthentErrorType("tooManyAttempts");
          } else {
            setAuthentError(false);
            setSnackbar({
              isOpen: true,
              message: "Une erreur est survenue. Merci de réessayer.",
              type: "error",
            });
          }
        } else {
          setAuthentError(false);
          setSnackbar({
            isOpen: true,
            message: "Une erreur est survenue. Merci de réessayer.",
            type: "error",
          });
        }
      });
  };

  return (
    <Layout noHeader>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "80vh" }}
      >
        <Grid item xs={12} sm={10}>
          <Box mb={7}>
            <Typography variant="h1">{t("login:title")}</Typography>
          </Box>
          <CAIHCard>
            <Formiz connect={authentForm} onValidSubmit={handleSubmit}>
              <Box mb={4}>
                <Typography variant="subtitle1">
                  {t("login:subtitle")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Grid item sm={6}>
                  <CAIHField
                    required
                    name="ipp"
                    fullWidth
                    label={t("login:ippLabel")}
                    toolTip={{
                      icon: <CobaltIcon name="help-circle" />,
                      content: <IppTooltip />,
                    }}
                    validations={[
                      {
                        rule: isRequired(),
                        message: t("form:error.required"),
                      },
                    ]}
                  />
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid item sm={6}>
                  <FormControl variant="outlined" required fullWidth>
                    <Box mb={2}>
                      <Typography variant="body1">
                        {t("login:clinicLabel")}
                      </Typography>
                    </Box>
                    <Select
                      labelId="clinic-select-label"
                      id="clinic-select"
                      fullWidth
                      onChange={changeClinic}
                      value={chosenClinic}
                      input={<Select />}
                    >
                      <MenuItem aria-label="empty" value={null}>
                        <em>‏‏‎ ‎‎‎</em>
                      </MenuItem>
                      {!isLoading &&
                        clinics.map(clinic => (
                          <MenuItem
                            key={clinic.healthFacilityId}
                            value={clinic.healthFacilityId}
                          >
                            {clinic.shortName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid item sm={6}>
                  <CAIHDatepicker
                    name="birthdate"
                    disabledPart="FUTURE"
                    required
                    fullWidth
                    validations={[
                      {
                        rule: isRequired(),
                        message: t("form:error.required"),
                      },
                    ]}
                  />
                </Grid>
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  endIcon={<CobaltIcon name="arrow-right" />}
                  disabled={
                    !authentForm.isValid ||
                    !FieldValidationUtil.isDateValid(
                      authentForm.values.birthdate,
                    ) ||
                    !chosenClinic ||
                    isDataNotReachable ||
                    isFetching
                  }
                >
                  {t("login:connect")}
                </Button>
              </Grid>
            </Formiz>
          </CAIHCard>
          {isDataNotReachable && (
            <Box mt={2} mb={2}>
              <ConsentLoginErrorBlock errorType="UNREACHABLE" />
            </Box>
          )}
          {authentError && authentErrorType !== "" && (
            <Box mt={2} mb={2}>
              <ConsentLoginErrorBlock errorType={authentErrorType} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Login;
