/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { CobaltIcon } from "da-design-system";
import CAIHCard from "../CAIHCard";
import TextSkeleton from "../TextSkeleton";

function ConsentContent(props) {
  const { content, isLoading, title } = props;

  const [showMore, setShowMore] = useState(false);
  const contentIsUnique = content.length === 1;
  const firstContentSize = content.length < 1 ? 0 : content[0].length;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { titleLabel, iconLeft } = title;

  const showMoreLabel = {
    fontFamily: "'Barlow', sans-serif",
    fontSize: 16,
    fontWeight: 500,
    color: "cobalt.bleuDigital100"
  };

  const showMoreBtn = {
    userSelect: "none",
    textAlign: "center",
    borderColor: "cobalt.grey10",
    "&:hover": {
      cursor: "pointer"
    }
  };
  const showMoreBtnMobile = {
    marginTop: "16px",
    paddingTop: "8px",
    borderTopStyle: "solid",
    borderTopWidth: "1px"
  };

  const getShowMoreBtnStyle = () => {
    if (isMobile) {
      return { ...showMoreBtn, ...showMoreBtnMobile };
    }
    return showMoreBtn;
  };

  const isContentOpen = isOpen => {
    if (isOpen) {
      return (
        <CobaltIcon
          name="chevron-up"
          style={{ fontSize: "20px", marginLeft: "8px" }}
        />
      );
    }
    return (
      <CobaltIcon
        name="chevron-down"
        style={{ fontSize: "20px", marginLeft: "8px" }}
      />
    );
  };

  const getDisplayedContents = () => {
    if (!showMore) {
      const firstContentArray = [];
      const firstElem =
        firstContentSize > 399
          ? `${content[0].substring(0, 399)}...`
          : content[0];
      firstContentArray.push(firstElem);

      return firstContentArray;
    }

    return content;
  };

  return (
    <Box>
      <CAIHCard hasPadding={false} cardContentNoPadding hasGutter>
        {isLoading ? (
          <Box p={4}>
            <TextSkeleton lines={5} />
          </Box>
        ) : (
          <Box p={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ marginBottom: "13px" }}
                >
                  {iconLeft !== null && iconLeft}
                  <Typography variant="h3">{titleLabel}</Typography>
                  {!isMobile && contentIsUnique && firstContentSize > 399 && (
                    <Box
                      sx={{
                        ...getShowMoreBtnStyle(),
                        borderColor: "cobalt.grey10",
                        marginLeft: "12px"
                      }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      <Box sx={{ ...showMoreLabel }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {showMore ? "Voir moins" : "Voir plus"}

                          {isContentOpen(showMore)}
                        </Grid>
                      </Box>
                    </Box>
                  )}
                  {!isMobile && !contentIsUnique && (
                    <Box
                      sx={{
                        ...getShowMoreBtnStyle(),
                        borderColor: "cobalt.grey10",
                        marginLeft: "12px"
                      }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      <Box sx={{ ...showMoreLabel }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {showMore ? "Voir moins" : "Voir plus"}

                          {isContentOpen(showMore)}
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Box
                  sx={{
                    position: "relative",
                    "& ul": {
                      listStyle: "none"
                    },
                    "& li": {
                      position: "relative",
                      fontFamily: "'Barlow', sans-serif",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      color: "#353846",
                      marginBottom: "16px"
                    },
                    "& li::before": {
                      position: "absolute",
                      top: 4,
                      left: -28,
                      borderRadius: "8px",
                      verticalAlign: "middle",
                      display: "inline-block",
                      width: "8px",
                      height: "8px",
                      background: "cobalt.lynch",
                      content: "''"
                    }
                  }}
                >
                  {contentIsUnique ? (
                    <Box
                      sx={{
                        fontFamily: "'Barlow', sans-serif",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "cobalt.ultramarine"
                      }}
                    >
                      {firstContentSize > 399
                        ? showMore
                          ? content[0]
                          : `${content[0].substring(0, 399)}...`
                        : content[0]}
                    </Box>
                  ) : (
                    <ul>
                      {getDisplayedContents().map((contentLabel, i) => (
                        <li key={`label-${i.toString()}`}>{contentLabel}</li>
                      ))}
                    </ul>
                  )}
                </Box>
              </Grid>
              {isMobile && contentIsUnique && firstContentSize > 399 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      ...getShowMoreBtnStyle(),
                      borderColor: "cobalt.grey10"
                    }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    <Box sx={{ ...showMoreLabel }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {showMore ? "Voir moins" : "Voir plus"}

                        {isContentOpen(showMore)}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              )}
              {isMobile && !contentIsUnique && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      ...getShowMoreBtnStyle(),
                      borderColor: "cobalt.grey10"
                    }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    <Box sx={{ ...showMoreLabel }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {showMore ? "Voir moins" : "Voir plus"}

                        {isContentOpen(showMore)}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </CAIHCard>
    </Box>
  );
}

ConsentContent.defaultProps = {
  title: {
    title: "",
    iconLeft: null
  }
};

ConsentContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.shape({
    titleLabel: PropTypes.string,
    iconLeft: PropTypes.node
  })
};

export default ConsentContent;
