import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const CAIHDrawerItem = props => {
  const { onClick, children } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        cursor: "pointer",
        fontSize: "14px",
        letterSpacing: "0.3px",
        color: "cobalt.bleuDigital100",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        padding: "8px"
      }}
      onClick={onClick}
    >
      {children}
    </Grid>
  );
};

CAIHDrawerItem.defaultProps = {
  onClick: () => {}
};

CAIHDrawerItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default CAIHDrawerItem;
