import React from "react";
import { SvgIcon } from "@mui/material";

export default function QuestionTooltipIcon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M10 2.5C5.86 2.5 2.5 5.858 2.5 10c0 4.142 3.359 7.5 7.5 7.5 4.143 0 7.5-3.358 7.5-7.5 0-4.142-3.357-7.5-7.5-7.5zM.835 10c0-5.063 4.104-9.167 9.167-9.167 5.062 0 9.166 4.104 9.166 9.167s-4.104 9.167-9.166 9.167C4.938 19.167.834 15.063.834 10z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.166 14.167c0-.46.373-.834.833-.834h.009c.46 0 .833.373.833.834 0 .46-.373.833-.833.833h-.009c-.46 0-.833-.373-.833-.833zM10.216 6.687c-.388-.067-.787.006-1.126.206-.34.2-.598.512-.728.884-.153.434-.629.662-1.063.51-.434-.154-.662-.63-.51-1.064.262-.742.777-1.368 1.456-1.767.679-.399 1.477-.545 2.253-.412.776.133 1.48.537 1.986 1.139.507.602.784 1.364.783 2.151 0 1.275-.945 2.118-1.62 2.568-.364.242-.72.42-.984.537-.133.059-.244.103-.325.134l-.097.036-.029.01-.009.003-.003.001h-.001l-.265-.79.264.79c-.437.147-.909-.09-1.054-.526-.146-.436.09-.908.526-1.054h-.001l.014-.005.062-.023c.056-.02.14-.054.241-.1.206-.09.474-.225.736-.4.575-.383.879-.79.879-1.182 0-.394-.138-.776-.392-1.077-.253-.3-.605-.503-.993-.57z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
