import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Divider, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TntImg from "../images/tnt-doca.png";

const CAIHFooter = (props) => {
  const { legalNotice, gtu } = props;
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  const isLegalNoticeEmpty = useMemo(() => !legalNotice.length > 0, [
    legalNotice,
  ]);
  const isGtuEmpty = useMemo(() => !gtu.length > 0, [gtu]);

  if (isNotMobile) {
    return (
      <Box
        sx={{
          height: "48px",
          marginTop: "66px",
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mr={6}
            >
              {!isLegalNoticeEmpty && (
                <Box
                  sx={{ userSelect: "none", cursor: "pointer" }}
                  style={{ marginLeft: "84px", marginRight: "0px" }}
                  onClick={() => window.open(legalNotice)}
                >
                  <Typography variant="body2">Mentions légales</Typography>
                </Box>
              )}
              {!isLegalNoticeEmpty && !isGtuEmpty && (
                <Divider
                  orientation="vertical"
                  style={{
                    height: "20px",
                    width: "1px",
                    marginLeft: "12px",
                    marginRight: "12px",
                  }}
                />
              )}

              {!isGtuEmpty && (
                <Box
                  sx={{ userSelect: "none", cursor: "pointer" }}
                  onClick={() => window.open(gtu)}
                >
                  <Typography variant="body2">CGU</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <img
                src={TntImg}
                alt="TNT"
                style={{ width: "100px", height: "auto", marginRight: "72px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return null;
};

CAIHFooter.propTypes = {
  legalNotice: PropTypes.string,
  gtu: PropTypes.string,
};

CAIHFooter.defaultProps = {
  legalNotice: "",
  gtu: "",
};

export default CAIHFooter;
