/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button, Link, Typography } from "@mui/material";

const AskOTP = props => {
  const { generateOtpFunc, variant } = props;

  const { t } = useTranslation();
  const [time] = useState(0);

  const handleClick = () => {
    generateOtpFunc();
  };

  return (
    <>
      {variant === "link" ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="body2"
              sx={{ color: "cobalt.lynch", mt: "12px" }}
            >
              {t("consentLogin:modal.phone.step2.infos2")}
            </Typography>
          </Grid>
          <Grid item sx={{ mt: "12px", ml: "3px" }}>
            <Link href="#" disabled={time !== 0} onClick={handleClick}>
              {t("consentLogin:modal.phone.step2.link")}
            </Link>
          </Grid>
        </Grid>
      ) : (
        <Button disabled={time !== 0} onClick={handleClick} variant="outlined">
          {t("otp:buttonLabel")}
        </Button>
      )}
    </>
  );
};

AskOTP.propTypes = {
  generateOtpFunc: PropTypes.func.isRequired,
  variant: PropTypes.string
};

AskOTP.defaultProps = {
  variant: "button"
};

export default AskOTP;
