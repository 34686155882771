import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  CssBaseline,
  Container,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { CobaltIcon } from "da-design-system";
import CAIHFooter from "./CAIHFooter";
import CAIHSnackbar from "./CAIHSnackbar";
import CAIHDrawer from "./CAIHDrawer";
import Modal from "./Modal";
import Nav from "./Nav";
import ConsentFrequentAsk from "./consent/ConsentFrequentAsk";
import CobaltMobileModal from "./CobaltMobileModal";
import AuthService from "../services/AuthService";

const LogoLayout = (props) => {
  const {
    children,
    logo,
    containerSize,
    legalNotice,
    gtu,
    headerStepper,
    withFooter,
  } = props;
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Ouverture du drawer version desktop
  const [isOpen, setIsOpen] = useState(false);
  // Controle de la modale de la FAQ
  const [mobileFAQControl, setMobileFAQControl] = useState(false);

  const handleClick = () => {
    AuthService.logout();
    history.push("/");
  };

  const hideLogo = headerStepper !== null;
  const headerStepperCurrentStep =
    headerStepper !== null ? headerStepper.props.currentStep : 0;

  const getHeaderMarginBottom = () => {
    if (headerStepperCurrentStep !== 1) {
      if (isMobile) {
        return "16px";
      }
      return "56px";
    }
    return "16px";
  };

  const handleFAQOpening = () => {
    setMobileFAQControl(true);
    setIsOpen(false);
  };

  const handleDrawerOpening = () => {
    setMobileFAQControl(false);
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        overflowX: "hidden",
        position: "relative",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          overflowX: "hidden",
          position: "relative",
          zIndex: 1301,
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 45, 0.04), 0px 0px 2px 0px rgba(0, 0, 45, 0.06), 0px 4px 8px 0px rgba(0, 0, 46, 0.04)",
          marginBottom: getHeaderMarginBottom(),
          flexGrow: 1,
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: "cobalt.white" }}>
          <Toolbar
            disableGutters
            style={{ height: isMobile ? "64px" : "88px" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {hideLogo ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ userSelect: "none" }}
                >
                  {headerStepper}
                </Grid>
              ) : (
                <Box>
                  {logo !== null && logo !== "" && (
                    <img
                      src={`data:image/jpeg;base64,${logo}`}
                      alt="logo"
                      style={{
                        height: isMobile ? "32px" : "48px",
                        width: "auto",
                        marginLeft: "32px",
                      }}
                    />
                  )}
                </Box>
              )}
            </Grid>
          </Toolbar>
          {!isMobile && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                position: "absolute",
                right: 32,
                height: "40px",
                width: "auto",
                cursor: "pointer",
                top: isMobile ? "12px" : "24px",
              }}
            >
              <Button
                variant="delta"
                onClick={() => setIsOpen(!isOpen)}
                startIcon={<CobaltIcon name="help-circle" />}
                sx={{
                  backgroundColor: isOpen ? "cobalt.bleuDigital05" : "initial",
                }}
              >
                Questions fréquentes
              </Button>
              {AuthService.isAuthenticated() && (
                <Button
                  variant="delta"
                  onClick={handleClick}
                  startIcon={<CobaltIcon name="log-out" />}
                >
                  Déconnexion
                </Button>
              )}
            </Grid>
          )}

          {isMobile && headerStepper === null && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                position: "absolute",
                right: 32,
                height: "40px",
                width: "auto",
                cursor: "pointer",
                top: isMobile ? "12px" : "24px",
              }}
              onClick={handleDrawerOpening}
            >
              {isOpen ? (
                <CobaltIcon
                  name="x"
                  style={{
                    color: theme.palette.cobalt.ultramarine,
                    fontSize: "24px",
                    height: "auto",
                    margin: "8px",
                  }}
                />
              ) : (
                <CobaltIcon
                  name="menu"
                  style={{
                    color: theme.palette.cobalt.ultramarine,
                    fontSize: "24px",
                    height: "auto",
                    margin: "8px",
                  }}
                />
              )}
            </Grid>
          )}
        </AppBar>
        <CAIHDrawer
          isOpen={isOpen}
          anchor={isMobile ? "top" : "right"}
          isFAQ={!isMobile}
        >
          {isMobile ? (
            <Nav
              isAuthenticated={AuthService.isAuthenticated()}
              handleFAQOpening={handleFAQOpening}
              legalNotice={legalNotice}
              gtu={gtu}
            />
          ) : (
            <ConsentFrequentAsk
              drawerControl={() => setIsOpen(!isOpen)}
              isMobile={isMobile}
            />
          )}
        </CAIHDrawer>
      </Box>
      <Box mb={headerStepperCurrentStep === 1 ? "6px" : "12px"}>
        <Container maxWidth={containerSize}>{children}</Container>
      </Box>
      {withFooter && (
        <Box pb={6}>
          <CAIHFooter legalNotice={legalNotice} gtu={gtu} />
          <CAIHSnackbar />
          <CobaltMobileModal isOpen={mobileFAQControl}>
            <ConsentFrequentAsk
              drawerControl={() => setMobileFAQControl(false)}
            />
          </CobaltMobileModal>
          <Modal />
        </Box>
      )}
    </Box>
  );
};

LogoLayout.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.string,
  containerSize: PropTypes.string,
  legalNotice: PropTypes.string,
  gtu: PropTypes.string,
  headerStepper: PropTypes.node,
  withFooter: PropTypes.bool,
};

LogoLayout.defaultProps = {
  children: null,
  logo: null,
  containerSize: "md",
  legalNotice: "",
  gtu: "",
  headerStepper: null,
  withFooter: true,
};

export default withTranslation()(LogoLayout);
