// Dépendances
import React, { useState, useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

// Services
import AuthService from "../services/AuthService";

// Routes
import Login from "./components/Login";
import ConsentLogin from "./components/ConsentLogin";
import NewConsent from "./components/NewConsent";
import Consents from "./components/Consents";
import ConsentSuccess from "./components/ConsentSuccess";
import InterventionListing from "./components/InterventionListing";
import InterventionSignature from "./components/InterventionSignature";
import InterventionSignedDocumentsListing from "./components/InterventionSignedDocumentsListing";
import IFrameLoader from "../components/intervention/IFrameLoader";
import PatientInformationsForm from "./components/PatientInformationsForm";

// Dépendances spécifiques aux routes
import Resolver from "./Resolver";
import history from "./history";
import { PUBLIC_ROUTING, PRIVATE_PATIENT_ROUTING } from "./routes";

const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthService.isAuthenticated()
  );

  useEffect(() => {
    if (
      AuthService.isAuthenticated() &&
      (history.location.pathname === "/consent" ||
        history.location.pathname === "/information")
    ) {
      setIsAuthenticated(false);
      AuthService.logout();
    }
    history.listen(() => {
      setIsAuthenticated(AuthService.isAuthenticated());
    });
  }, [isAuthenticated]);

  const getRouteComponent = (route) => {
    switch (route.key) {
      case "login":
        return Login;
      case "consent-login":
        return ConsentLogin;
      case "information":
        return ConsentLogin;
      case "new-consent":
        return NewConsent;
      case "consent-success":
        return ConsentSuccess;
      case "consents":
        return Consents;
      case "interventions":
        return InterventionListing;
      case "intervention-sign":
        return InterventionSignature;
      case "intervention-signed":
        return InterventionSignedDocumentsListing;
      case "intervention-validate":
        return IFrameLoader;
      case "form":
        return PatientInformationsForm;
      default:
        break;
    }

    return null;
  };

  const renderRoute = (route) => {
    const component = getRouteComponent(route);

    if (!component) {
      return null;
    }

    return <Route exact component={component} {...route} />;
  };

  return (
    <Router history={history}>
      <Switch>
        {!isAuthenticated && PUBLIC_ROUTING.map(renderRoute)}
        {!isAuthenticated && <Redirect to="/" />}

        {isAuthenticated && PRIVATE_PATIENT_ROUTING.map(renderRoute)}
        {isAuthenticated && <Redirect to={Resolver.resolve("consents")} />}
      </Switch>
    </Router>
  );
};

export default Routes;
