import hubService from "./HubService";
import config from "../config";

const USER = "user";
const OPERATOR = "operator_token";
const O_SESSION_STATE = "operator_session_state";

const authService = {
  isAuthenticated: () =>
    localStorage.getItem(USER) ? localStorage.getItem(USER) : false,
  getUserData: () => JSON.parse(localStorage.getItem(USER)),
  getTokenOperator: () => JSON.parse(localStorage.getItem(OPERATOR)),
  setDataConnection: user => {
    localStorage.setItem(USER, JSON.stringify(user), { path: "/" });
  },
  setOperatorToken: token => {
    localStorage.setItem(OPERATOR, JSON.stringify(token), { path: "/" });
  },
  setOperatorSessionState: oSS => {
    localStorage.setItem(O_SESSION_STATE, JSON.stringify(oSS), { path: "/" });
  },
  getUserInfo: async () => {
    const { HUB_KEYCLOAK_DOMAIN } = config;
    const url = `${HUB_KEYCLOAK_DOMAIN}protocol/openid-connect/userinfo`;

    return hubService.keycloakRequest("GET", url);
  },
  logout: () => {
    localStorage.removeItem(USER);
  }
};

export default authService;
