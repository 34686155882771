import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const ConsentKnowMore = props => {
  const { title, description } = props;

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
};

ConsentKnowMore.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default ConsentKnowMore;
